/* eslint-disable @typescript-eslint/camelcase */
import { Component, ViewEncapsulation, OnInit, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, Meta, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { VideosApi } from 'src/app/services/api/videos-api.service';
import { AppManager } from 'src/app/services/app-manager.service';
import { NGXLogger } from 'ngx-logger';
import { NavController } from '@ionic/angular';

@Component({
    selector: 'app-reach-all',
    templateUrl: 'reach-all.html',
    styleUrls: ['../video-player/video-player-page.scss', 'reach-all.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ReachAll implements OnInit {
    overlayActive = true;
    loadVideoPlayer = false;
    overlayOpacity = 0.5;
    video_id = '';
    showLoadingProgress = true;
    iframeLoaded = false;
    failedServerResponse: { msg?: string; };
    safeUrl: SafeResourceUrl;
    imageURL = '';
    videoTitle = '';
    backgroundImageUrl: SafeUrl;
    customURLLogo: string;
    constructor(private elementRef: ElementRef,
        private _route: ActivatedRoute,
        private sanitizer: DomSanitizer,
        private navCtrl: NavController,
        private logger: NGXLogger,
        private appManager: AppManager,
        private metaService: Meta,
        private videosApi: VideosApi) {}

    async ngOnInit() {
        const redirectToStart = () => {
            setTimeout(() => {
                this.navCtrl.navigateRoot('/');
            }, 3000);
        };
        if (this._route.snapshot.params || this._route.snapshot.params.video_id) {
            this.video_id = this._route.snapshot.params.video_id;
            this.safeUrl = this.generateSafeUrl();
            this.videosApi.getUserSpecificVideoDetails(this.video_id).subscribe(data => {
                // this.showLoadingProgress = false;
                // if (data?.success === false && data?.reachall) {
                //     this.showLoadingProgress = false;
                //     // this.uiUtils.displayToast(data.msg);
                //     this.backgroundImageUrl = '/assets/img/2024_video_deactivated.svg';
                //     this.showReachAllVideoDeactivated = true;
                //     return;
                // }
                //@ts-ignore
                if (data?.success === false) {
                    this.failedServerResponse = data as {success: boolean; code: string; msg: string;};
                    this.logger.debug('Got failedServerResponse', this.failedServerResponse);
                    this.metaService.updateTag({ name: 'robots', content: 'noindex' });
                    // redirectToStart();
                    return;
                }
                this.videoTitle = data['title'];
                this.imageURL = data['imageURL'];
                this.customURLLogo = data['customURLLogo'];
            });
        }
    }
    updateBackgroundImageUrl() {
        this.backgroundImageUrl = this.sanitizer.bypassSecurityTrustStyle(`url(${this.imageURL})`);
    }

    loadIframe() {
        setTimeout(() => {
            this.showLoadingProgress = true;
        }, 100);
        const iframe = this.elementRef.nativeElement;

        // if (iframe.requestFullscreen) {
        //     iframe.requestFullscreen();
        // } else if (iframe.mozRequestFullScreen) {
        //     iframe.mozRequestFullScreen();
        // } else if (iframe.webkitRequestFullscreen) {
        //     iframe.webkitRequestFullscreen();
        // } else if (iframe.msRequestFullscreen) {
        //     iframe.msRequestFullscreen();
        // }
        this.loadVideoPlayer = true;
    }

    onIframeLoad() {
        this.iframeLoaded = true;
        setTimeout(() => {
            this.showLoadingProgress = false;
        }, 100);
    }

    toggleOverlay() {
        this.overlayActive = false;
        this.loadIframe();
    }

    onImageMouseOver() {
        this.overlayOpacity = 0.3; // Set the desired opacity on mouseover
    }

    onImageMouseOut() {
        this.overlayOpacity = 0.5; // Set the initial opacity value on mouseout
    }
    ionViewWillEnter() {
        // this.startIntroAnimation(); // disable this as workaround in the meantime cause memory leak
    }

    generateSafeUrl(): SafeResourceUrl {
        let url = '';
        if (this._route.snapshot.params.class_id && this._route.snapshot.params.clip_id) {
            const class_id = this._route.snapshot.params.class_id;
            const clip_id = this._route.snapshot.params.clip_id;
            url = `${this.appManager.appBaseUrl}/embed/${this.video_id}/${class_id}/clip/${clip_id}?autostart=true`;
        } else {
            url = `${this.appManager.appBaseUrl}/embed/${this.video_id}?autostart=true`;
        }
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }

    onImageLoad() {
        setTimeout(() => {
            this.showLoadingProgress = false;
        }, 100);
    }
}
