/* eslint-disable key-spacing */
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production          : false,
    hmr                 : false,
    spyderBaseUrl       : 'https://spyder3-dev.uugot.it/v3',
    serviceApiBaseUrl   : 'https://service-api-dev.uugot.it/v3',
    appBaseUrl          : 'https://app-dev.uugot.it',
    environmentName     : 'DEV',

    /**
     * Where the files are uploaded to ('https://uugotitdevstorage.blob.core.windows.net' for example)
     */
    azureStorageAccount : 'uugotitdev',

    // In thise countries, all videos will be accessible for not logged-in users:
    enableAllVideosInCountries: ['AT', 'HR'],

    // Google Analyitcs Measurement ID - if this is not set, then the "gtag" script will not be loaded
    // googleAnalyticsKey  : 'G-0N4MLDXGRL'
    googleAnalyticsKey  : '',

    mixpanelProjectToken: '',
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
