<div *ngIf="selected.length; else placeholder">
    <span *ngFor="let item of selected; let last = last">
        {{ leaf(item) }}{{ last ? "" : ", " }}
    </span>
</div>

<ng-template #placeholder><span class="placeholder-text">{{'select_subjects' | translate}} </span></ng-template>

<ion-modal trigger="open-modal" [isOpen]="isOpen">
    <ng-template>
        <ion-header>
            <ion-toolbar>
                <ion-buttons slot="start">
                    <ion-button (click)="close()">Cancel</ion-button>
                </ion-buttons>
                <ion-title>{{ title }}</ion-title>
                <ion-buttons slot="end">
                    <ion-button (click)="confirm()" [strong]="true">Select</ion-button>
                </ion-buttons>
            </ion-toolbar>
            <ion-toolbar>
                <ion-searchbar (ionChange)="filter($event)">

                </ion-searchbar>
            </ion-toolbar>
        </ion-header>
        <ion-content class="ion-padding">
            <ion-item *ngFor="let item of filtered">
                <ion-checkbox slot="start" [(ngModel)]="item.selected" (ionChange)="itemSelected()"></ion-checkbox>
                <ion-label>{{ leaf(item) }}</ion-label>
            </ion-item>
        </ion-content>
    </ng-template>
</ion-modal>
