<ion-content class="video-overlay-center-middle deactivated-bg" [class.mobile-landscape-videoplayer]="true" *ngIf="failedServerResponse">
    <div class="centered-content">
        <ion-img src="assets/img/yuyu_yellow_sad2.svg" class="deactivated-img"></ion-img>
        <p class="deactivated" [innerHTML]="'reachall_video_deactivated' | translate"></p>
    </div>
</ion-content>
<ion-content  [class.mobile-landscape-videoplayer]="true" *ngIf="!failedServerResponse">
    <ion-progress-bar type="indeterminate" *ngIf="showLoadingProgress" class="video-player-progress-bar"></ion-progress-bar>

    <div id="video-container" #videoContainer *ngIf='!loadVideoPlayer'>
        <div class="video-overlay-center-middle" [class.overlay-active]="overlayActive">
            <ion-img id="imageInIframe" class="video-js  vjs-default-skin vjs-big-play-centered" *ngIf='imageURL' [src]="imageURL"
                     (click)="loadIframe()"
                     (ionImgDidLoad)="onImageLoad()"
                     (mouseover)="onImageMouseOver()"
                     (mouseout)="onImageMouseOut()">
            </ion-img>
            <div class="overlay" [style.background-color]="'rgba(0, 0, 0, ' + overlayOpacity + ')'"></div>
            <div class="centered-content">
                <div class="video-title">
                    <a href="" target="_blank">{{videoTitle}}</a>
                </div>
                <ion-img src="assets/img/video-player/play-circle-outline.svg" *ngIf='imageURL' class="video-play-button" (click)="toggleOverlay()"></ion-img>
                <div class="video-info">
                    <ion-icon name="information-circle"></ion-icon>
                    <span [innerHTML]="'video_intro_info' | translate"></span>
                </div>
            </div>
        </div>
        <div id="video-overlay-intro" *ngIf='!loadVideoPlayer'>
<!--            <div class="video-overlay-intro-container2">-->
<!--                <div class="video-title">-->
<!--                    <a href="" target="_blank">{{videoTitle}}</a>-->
<!--                </div>-->
<!--                <div class="video-info">-->
<!--                    &lt;!&ndash; https://ga-dev-tools.appspot.com/campaign-url-builder/ &ndash;&gt;-->
<!--                    <ion-icon name="information-circle"></ion-icon>-->
<!--                    <span [innerHTML]="'video_intro_info' | translate"></span>-->
<!--                </div>-->
<!--            </div>-->
            <ion-row class="video-subtitle">
                <ion-col class="video-subtitle-flex-center" size="9">
<!--                    <span [innerHTML]="'video_intro_subinfo_1' | translate"></span>-->
<!--                    <span [innerHTML]="'video_intro_subinfo_2' | translate"></span>-->
                </ion-col>
<!--                <ion-col class="video-subtitle-flex-end">-->
<!--                    <ion-img src="assets/img/video-player/EN_Co-Funded_by_the_EU_POS.png" class="video-subtitle-img" *ngIf='imageURL'></ion-img>-->
<!--                </ion-col>-->
            </ion-row>
<!--            <div id="video-subtitle-intro" class="video-subtitle" style="display: block;">-->
<!--                <span id="video-subtitle-inner-intro" class="video-subtitle-inner" [innerHTML]="introAnimation.currentSubtitle"></span>-->
<!--            </div>-->
<!--            <img id="click-cursor" src="assets/img/video-player/hand-pointer-icon.png" />-->
        </div>
    </div>

    <iframe
        *ngIf='loadVideoPlayer'
        class=""
        [src]="safeUrl"
        (load)="onIframeLoad()"
        frameborder='0'
        allowfullscreen></iframe>

</ion-content>
