<ion-content class="ion-padding"*ngIf="failedServerResponse">
    <p>{{failedServerResponse.msg}}</p>
    <p>{{'redirect_to_video_catalog' | translate}}</p>
    <ion-button routerLink="/" routerDirection="root">{{'all_videos' | translate}}</ion-button>
</ion-content>

<ion-content *ngIf="!failedServerResponse" [class.mobile-landscape-videoplayer]="true" [class.click-delay-timeout]="clickDelay.timeout">
    <ion-progress-bar type="indeterminate" *ngIf="showLoadingProgress" class="video-player-progress-bar"></ion-progress-bar>
    <ion-grid class="ion-no-padding">
        <ion-row>
            <ion-col size="12" class="ion-no-padding">
                <!-- START video-container -->
                <div id="video-container" #videoContainer [hidden]="!videoInfo"
                    [class.video-player-locked]="isLocked"
                    [class.video-player-unlocked]="!isLocked"
                    [class.video-player-delay-paused-infinite]="clickDelay.isPausedInfinite">
                    <ion-img *ngIf='customURLLogo' [src]="customURLLogo" id="logo-image" alt="Logo"></ion-img>

                    <!-- START main_video -->
                    <video id="main_video" class="video-js vjs-16-9 vjs-default-skin vjs-big-play-centered"
                        webkit-playsinline playsinline>
                        <!--<source src="{{videoInfo.url.medium}}" type="video/mp4">-->
                        <!--<source src="movie.ogg" type="video/ogg">-->
                        <!--Your browser does not support the video tag.-->
                        <p class="vjs-no-js">
                            To view this video please enable JavaScript, and consider upgrading to a web browser that
                            <a href="http://videojs.com/html5-video-support/" target="_blank">supports HTML5 video</a>
                        </p>
                    </video>
                    <!-- END main_video -->

                    <!-- An overlay that hides burned-in subtitles -->
                    <div id="video-overlay-hide-burned-subtitles"
                        [style.visibility]="videoInfo && videoInfo.websource_id === 'testtv' && videoInfo.tags?.includes('einfache-sprache') && currentTime && currentTime > 0 ? 'visible' : 'hidden'">
                    </div>

                    <div id="video-postroll" class="vjs-poster" [ngClass]="videoInfo?.websource_id"></div>
                    <div id="video-subtitle" class="video-subtitle video-subtitle-fontsize-multiplier-{{subtitleFontSize}}">
                        <span id="video-subtitle-inner" class="video-subtitle-inner" [innerHTML]="currentSubtitle" [style.display]="hideSubtitles ? 'none' : 'inline'">
                        </span> <!-- Note: Using ngIf here doesn't work because we need the element in the DOM -->
                    </div>

                    <ion-button slot="icon-only" fill="outline" color="light" (click)="presentPopover($event)"
                        class="video-overlay-button button-more" *ngIf="showActionMenu">
                        <ion-icon name="more"></ion-icon>
                    </ion-button>

                    <div id="video-overlay-topleft-container">
                        <div
                            class="video-overlay-button-no-border video-overlay-button-icon"
                            id="video-back-overlay-button" (click)="navigateBack()">
                            <!-- <ion-icon name="arrow-back"></ion-icon> -->
                            <ion-icon name="close"></ion-icon>
                        </div>
                        <!-- uugot.it TV logo that is shown top left of the video -->
                        <a href="https://app.uugot.it?utm_source=player_topleft_logo&utm_medium=iframe&utm_campaign=reachall" target="_blank">
                            <img class="uugotit-app-logo" src="assets/img/logo_app_uugotit.png" alt="app.uugot.it - learning languages while watching TV" title="uugot.it - learning languages while watching TV"/>
                        </a>
                    </div>

                    <div id="video-overlay-buttons-container">
                        <!-- <ion-button slot="icon-only" fill="outline" color="light" (click)="presentPopover($event)" class="video-overlay-button button-more-fullscreen"
                            *ngIf="isVideoFullscreen">
                            <ion-icon name="more"></ion-icon>
                        </ion-button> -->

                        <div slot="icon-only" fill="clear" color="light" (click)="toggleSubtitlesOnOff(); showTopOverlayButtonValue(videoTopButtonValueSubOnOff);"
                            title="{{ 'player_switch_subtitles_button_title' | translate }}"
                            *ngIf="videoInfo?.hasSubtitle"
                            class="video-overlay-button-no-border button-subtitles-onoff"
                            [style.text-decoration]="hideSubtitles ? 'line-through' : 'none'">
                            <!-- Text:
                                {{ "button_subtitles_short" | translate }}
                            -->
                            <span class="video-overlay-button-value" #videoTopButtonValueSubOnOff>{{(hideSubtitles ? 'subtitles_hidden' : 'subtitles_shown') | translate}}</span>
                            <!-- Image with ON / OFF: -->
                            <img [src]="hideSubtitles ? 'assets/img/video-player/subtitles_off.png' : 'assets/img/video-player/subtitles_on.png'" />
                        </div>
                        <!-- <div slot="icon-only" fill="clear" color="light" (click)="switchPlaybackRateCallback(+1)" title="{{ 'player_playback_rate_button_title' | translate }}"
                            class="video-overlay-button-no-border button-change-rate">
                            {{playbackRate}}X
                        </div> -->
                        <div (click)="switchPlaybackRateCallback(+1); showTopOverlayButtonValue(videoTopButtonValuePlaybackRate);"
                            class="video-overlay-button-icon button-settings video-overlay-button-speed"
                            title="{{ 'player_playback_rate_button_title' | translate }}">
                            <!--
                            <ion-icon name="speedometer" ioniconRemoveTitle iconTitle="{{ 'player_playback_rate_button_title' | translate }}" class="background"
                                [style.clip-path]="'inset(0px ' + (3 - playbackRateIndex) / 4 * 100 + '% 0px 0px)'"></ion-icon>
                            <ion-icon name="speedometer-outline" ioniconRemoveTitle iconTitle="{{ 'player_playback_rate_button_title' | translate }}"></ion-icon>
                            -->
                            <span class="video-overlay-button-value" #videoTopButtonValuePlaybackRate>
                                <ng-container *ngIf="playbackRate !== undefined">
                                    {{playbackRate * 100}} %
                                </ng-container>
                            </span>
                            <ng-container *ngIf="playbackRateIndex !== undefined">
                                <img class="video-overlay-button-icon-svg" src="assets/img/video-player/speedometer-background-dirtyyellow.svg" class="speed-background speed-background-{{(playbackRateIndex + 1)}}"/>
                                <!-- [style.clip-path]="'inset(0px ' + (3 - playbackRateIndex) / 4 * 100 + '% 0px 0px)'" -->
                                <img class="video-overlay-button-icon-svg" [src]="'assets/img/video-player/speedometer-outline-' + (playbackRateIndex + 1) + '.svg'"/>
                            </ng-container>
                        </div>

                        <!-- <div slot="icon-only" fill="clear" color="light" (click)="skipVideo(-10, 'button')" title="{{ 'player_skip_video_back_button_title' | translate }}"
                            class="video-overlay-button-no-border button-skip button-skip-rewind">
                            <img src="assets/img/video-player/icon-back.png" />
                        </div>
                        <div slot="icon-only" fill="clear" color="light" (click)="skipVideo(+10, 'button')" title="{{ 'player_skip_video_fwd_button_title' | translate }}"
                            class="video-overlay-button-no-border button-skip button-skip-forward">
                            <img src="assets/img/video-player/icon-forward.png" />
                        </div> -->
                        <div (click)="showSettingsOverlay()" class="video-overlay-button-icon button-settings">
                            <ion-icon name="settings-outline" ioniconRemoveTitle iconTitle="{{ 'settings' | translate }}"></ion-icon>
                        </div>

                        <div
                            *ngIf="(videoInfo && (videoInfo.isFromReachAll && videoInfo.enableThirdPartySharing)) || (videoInfo && !videoInfo.isFromReachAll) "
                            (click)="openShareDialog()"
                            class="video-overlay-button-icon button-settings">
                            <ion-icon name="share-social" ioniconRemoveTitle iconTitle="{{ 'btn_share_video' | translate }}"></ion-icon>
                        </div>
                    </div>

                    <div id="video-overlay-center-container" class='video-embed'>
                        <div (click)="skipVideo(-10, 'button', true)" title="{{ 'player_skip_video_back_button_title' | translate }}"
                            class="video-overlay-center-button video-overlay-center-leftButton">
                            <img src="assets/img/video-player/skip-back-10sec.svg" alt="skip back 10 seconds" />
                        </div>
                        <div class="video-overlay-center-button video-overlay-center-centerButton" [style.display]="videoJSplayer && videoJSplayer.paused() ? 'inline' : 'none'" (click)="playVideo()"
                            title="{{ 'player_play_button' | translate }}">
                            <!-- <ion-icon name="play-circle-outline" ioniconRemoveTitle iconTitle="{{ 'player_play_button' | translate }}"></ion-icon> -->
                            <img src="assets/img/video-player/play-circle-outline.svg" alt="play video" />
                        </div>
                        <div class="video-overlay-center-button video-overlay-center-centerButton" [style.display]="videoJSplayer && !videoJSplayer.paused() ? 'inline' : 'none'"  (click)="pauseVideo()"
                            title="{{ 'player_pause_button' | translate }}">
                            <!-- <ion-icon name="pause-circle-outline" ioniconRemoveTitle iconTitle="{{ 'player_pause_button' | translate }}"></ion-icon> -->
                            <img src="assets/img/video-player/pause-circle-outline.svg" alt="pause video" />
                        </div>
                        <div (click)="skipVideo(+10, 'button', true)" title="{{ 'player_skip_video_fwd_button_title' | translate }}"
                            class="video-overlay-center-button video-overlay-center-rightButton">
                            <!-- <img src="assets/img/video-player/icon-forward.png" /> -->
                            <img src="assets/img/video-player/skip-ahead-10sec.svg" alt="skip ahead 10 seconds" />
                        </div>

                        <!-- <ion-button class="video-overlay-buttons-center-leftButton">left</ion-button>
                        <ion-button class="video-overlay-buttons-center-rightButton">right</ion-button> -->
                    </div>
                    <video-player-educator-overlay id="video-player-educator-overlay-component"
                                                   [hidden]="!appData.isEducator()"
                                                   [videoJSplayer]="videoJSplayer"
                                                   [video]="videoInfo"
                                                   [(clip)]="clip"
                                                   [isEmbed]='true'
                                                   [class_id]="class_id"
                                                   [listener]="this"></video-player-educator-overlay>

                    <video-player-task-overlay id="video-player-task-overlay-component"
                                               [clip]="clip"
                                               [class_id]="class_id"
                                               [listener]="this"
                                               [isEmbed]='true'
                                               [videoJSplayer]="videoJSplayer"></video-player-task-overlay>



                    <!-- Button on the right side (lock/unlock) -->
                    <div id="video-overlay-middleright-container" [hidden]="!isMobileLandscape()">
                        <video-player-screen-lock></video-player-screen-lock>
                    </div>

                    <div id="video-overlay-bottom-insert"
                        *ngIf="videoInfo && videoInfo.websource_id === 'orftvthek' && !appData.playedVideoOfSource.get(videoInfo.websource_id) && !settingsOverlayShown">
                        {{ 'infotext_async_subtitles' | translate }}
                    </div>

                    <!-- START video-overlay-intro -->
                    <div id="video-overlay-intro" *ngIf="introAnimation && introAnimation.isShown">
                        <div class="video-overlay-intro-container" *ngIf="introAnimation.currentScreen === 'initial'">
                            <div class="video-overlay-video-title" *ngIf="(videoInfo && (videoInfo.isFromReachAll && videoInfo.enableThirdPartySharing)) || (videoInfo && !videoInfo.isFromReachAll) ">
                                <a href="{{shareOptions.url}}?utm_source=intro_videotitle&utm_medium=iframe&utm_campaign=reachall" target="_blank">{{videoInfo.title}}</a>
                            </div>
                            <div class="video-overlay-intro-info">
                                <!-- https://ga-dev-tools.appspot.com/campaign-url-builder/ -->
                                <ion-icon name="information-circle"></ion-icon>
                                <span [innerHTML]="'video_intro_info' | translate"></span>
                            </div>
                            <div class="video-overlay-intro-nextbutton">
                                <ion-button fill="solid" color="secondary" (click)="introStartButtonClicked(true)" size="small"> <!-- introAnimation.nextButtonClicked() -->
                                    <!-- <ion-icon slot="start" name="checkmark"></ion-icon> -->
                                    <ion-icon slot="start" name="arrow-forward"></ion-icon>
                                    {{ 'button_continue' | translate }}
                                </ion-button>
                            </div>
                        </div>
                        <div class="video-overlay-intro-container" *ngIf="introAnimation.currentScreen === 'select_language'">
                            <div class="video-overlay-intro-languageselect">
                                <span>{{ 'video_intro_choose_transl_language' | translate }}</span>
                                <ion-button fill="outline" color="light" size="small" (click)="showSettingsOverlay('language')">{{'lang_' + language | translate}}</ion-button></div>
                            <div class="video-overlay-intro-startbutton">
                                <ion-button fill="solid" color="secondary" (click)="introStartButtonClicked(true)" [size]="plt.is('mobile') ? 'small' : 'default'">
                                    <!-- <ion-icon slot="start" name="checkmark"></ion-icon> -->
                                    <ion-icon slot="start" name="play"></ion-icon>
                                    {{ 'video_intro_button_start' | translate}}
                                </ion-button>
                            </div>
                            <!-- <div class="video-overlay-intro-textclick">
                                {{ 'video_intro_textclick' | translate }}
                            </div> -->
                        </div>
                        <div id="video-subtitle-intro" class="video-subtitle" style="display: block;">
                            <span id="video-subtitle-inner-intro" class="video-subtitle-inner" [innerHTML]="introAnimation.currentSubtitle"></span>
                        </div>
                        <img id="click-cursor" src="assets/img/video-player/hand-pointer-icon.png" />
                    </div>
                    <!-- END video-overlay-intro -->


                    <!-- START video-postroll2 -->
                    <div id="video-postroll2" class="video-postroll2" *ngIf="showPostroll">

                        <div class="video-postroll2-container">
                            <div class="video-postroll2-left">
                                <div *ngIf="wordsClicked && wordsClicked.length > 0">
                                    <ion-grid class="ion-no-padding">
                                        <!-- <ion-row>
                                            <ion-col>{{ "original_word" | translate }}</ion-col>
                                            <ion-col>{{ "translated_word" | translate }}</ion-col>
                                        </ion-row> -->
                                        <ion-row *ngIf='isEmbedded'>
                                            <ion-button fill="solid" color="primary" size="medium" (click)="generateQuizletFile()">
                                                {{'download' | translate}}
                                            </ion-button>
                                        </ion-row>
                                        <ion-row *ngFor="let word of lastWordsClicked(); let i = index" [attr.data-index]="i">
                                            <ion-col class="originalWord">
                                                {{word.fromWord || word.oriWord}}
                                            </ion-col>
                                            <ion-col class="translatedWord">
                                                {{word.toWord}}
                                            </ion-col>
                                        </ion-row>
                                    </ion-grid>
                                </div>
                            </div>
                            <div class="video-postroll2-right ion-text-center">

                                <div class="video-postroll2-header text-shadow">
                                    {{'video_postroll_header' | translate}}
                                </div>

                                <div class="video-postroll2-subheader text-shadow">
                                    <span [innerHTML]="'video_postroll_subheader' | translate"></span>
                                </div>

                                <div class="video-postroll2-main-button">
                                    <ion-button fill="solid" color="secondary" size="medium"
                                        href="https://www.uugot.it/getapp" target="_blank">
                                        <!-- <ion-icon slot="start" name="star"></ion-icon> -->
                                        {{'video_postroll_btn_download' | translate}}
                                    </ion-button>

                                    <!-- <ion-icon name="logo-google-playstore"></ion-icon>&nbsp;
                                    <ion-icon name="logo-apple"></ion-icon>&nbsp;
                                    <ion-icon name="desktop"></ion-icon> -->

                                </div>

                                <div class="video-postroll2-secondary-button">
                                    <ion-button fill="outline" color="light" (click)="replayVideoFromStart()" size="small">
                                        <ion-icon slot="start" name="repeat"></ion-icon>
                                        {{'video_postroll_btn_repeat' | translate}}
                                    </ion-button>
                                </div>
                            </div>
                        </div>


                        <a href="https://app.uugot.it?utm_source=player_topleft_postroll&utm_medium=iframe&utm_campaign=reachall" target="_blank">
                            <img class="app-uugotit-logo" src="assets/img/logo_app_uugotit.png" alt="app.uugot.it"/>
                        </a>

                        <ion-button class="video-postroll2-close" *ngIf='!videoInfo.isFromReachAll' fill="clear" color="light"
                            (click)="hidePostroll()" title="{{'video_postroll_btn_close' | translate}}">
                            <ion-icon slot="icon-only" name="close"></ion-icon>
                        </ion-button>
                    </div>
                    <!-- END video-postroll2 -->

                    <!-- START video-overlay-settings -->
                    <video-player-settings *ngIf='videoInfo' [video]='videoInfo' [hidden]="!settingsOverlayShown" [listener]="this" id="video-player-settings"></video-player-settings>
                    <!-- END video-overlay-settings -->

                    <!-- START video-overlay-intro2 -->
                    <video-overlay-intro2 id="video-overlay-intro2" (onClose)="onCloseVideoIntroOverlay($event)"></video-overlay-intro2>
                    <!-- END video-overlay-intro2 -->

                    <ion-progress-bar [value]="clickDelay.countdownPercent" id="video-player-click-delay-progress"
                        style="position: absolute; top: 0px;" color="light" [hidden]="!clickDelay.showProgressBar"></ion-progress-bar>
                </div>
                <!-- END video-container -->

                <!-- <p style="display: none">Current time: {{currentTime}}</p> -->
            </ion-col>

        </ion-row>
    </ion-grid>

</ion-content>
