import { ILanguages } from '../interfaces/ILanguages';
import { CONSTANTS } from '../constants';
import { IVideo } from '../interfaces/IVideo';
import * as moment from 'moment';
import { IUser } from '../interfaces/IUser';
import { ILicense } from '../interfaces/ILicense';
import LicenseType from '../interfaces/ILicenseType';

export enum KEY_CODE {
    RIGHT_ARROW = 39,
    LEFT_ARROW = 37
}

export class Utils {

    /**
     * Returns the system language from a predefined set of languages that is supported, fallback to 'en'.
     */
    static getSystemLanguage(): string {
        const userLang = navigator.language.split('-')[0];
        return /(de|en)/gi.test(userLang) ? userLang : 'en';
    }

    static isInternalEmailAdress(email: string): boolean {
        return !email || email.endsWith('@uugot.it') || email.endsWith('@creativeworkline.at');
    }

    static removePunctuation(str: string): string {
        // Original from https://github.com/derHowie/remove-punctuation/blob/master/index.js
        // return str.replace(/[&\/\\#,+\(\)$~%\.!^'"\;:*?\[\]<>{}]/g, '');
        return str.replace(/[&/\\#,+()$~%.!^";:*?[\]<>{}]/g, '');
    }

    /**
     * Returns an array with two elements: word and punctuation (can be empty)
     * @param str a word like 'Test.'
     */
    static extractPunctuationAtEndOfWord(str: string): Array<string> {
        let punctuation = '';
        let str2 = str;
        if (str2.endsWith('.') || str2.endsWith(',') || str2.endsWith('!') || str2.endsWith('?') || str2.endsWith(':') || str2.endsWith(';')) {
            punctuation = str2.substring(str2.length - 1, str2.length);
            // console.log('punctuation: ' + punctuation);
            str2 = str2.substring(0, str2.length - 1);
            // console.log('str2: ' + str2);
        }
        return [str2, punctuation];
    }

    /**
     * Prepares a string to be used in a filenane (replaces whitespaces with - and removes non-word characters)
     * @param text the text to be used
     */
    static prepareForFilename(text: string): string {
        return text
            // .toLowerCase()
            .replace(/ /g, '-')
            .replace(/[^\p{L}\p{N}_-]+/gu, '');
    }

    static getDurationOfVideoInSeconds(video: {duration?: number; durMinutes?: number; offset?: number[];}): number {
        let duration: number;
        if (video.offset && video.offset.length === 2) {
            duration = video.offset[1] - video.offset[0];
        } else if (video.duration) {
            duration = video.duration;
        } else if (video.durMinutes) {
            duration = video.durMinutes * 60;
        } else {
            duration = 600; // 10 minutes, some fallback number
        }
        return duration;
    }

    /**
     * Create a new RegExp object that matches the word with boundaries.
     * Also works with unicode words.
     * NOTE: This doesn't work in Safari! lookbehind assertions ((?<= ) and (?<! )) are not supported:
     * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#browser_compatibility
     * @param str the word to search for
     * @param flags regex flags, defaults to 'ig'
     */
    static regexWordBoundaryUnicode(str: string, flags = 'ig'): RegExp {
        // See https://medium.com/@shiba1014/regex-word-boundaries-with-unicode-207794f6e7ed
        return new RegExp(`(?<=[\\s,.:;"']|^)${str}(?=[\\s,.:;"']|$)`, flags);
    }

    /**
     * Create a new RegExp object that matches the word with boundaries.
     * Also works with unicode words.
     * NOTE: This also matches the whitespace before and after the word. Use '$1word-to-replace$3' in replace() to keep those.
     * @param str the word to search for
     * @param flags regex flags, defaults to 'ig'
     */
    static regexWordBoundaryUnicodeInclWhitespace(str: string, flags = 'ig'): RegExp {
        return new RegExp(`([\\s,.:;"']|^)(${str})([\\s,.:;"']|$)`, flags);
    }

    static splitWordIntoLinesJoin(toWord: string, toLanguage: ILanguages, sepatator = '<br>', max: number = null): string {
        let words = toWord.split(' / ');
        let maxWords = max;
        if (!maxWords) {
            maxWords = words.length;
        }
        words = words.map(word => word.replace(/__START_NO_SHOW__(.*?)__END_NO_SHOW__/g, '$1').trim());
        if (CONSTANTS.RTL_LANGUAGES.includes(toLanguage)) { // TODO use constants
            words = words.reverse();
        }
        return words.slice(0, maxWords).join(sepatator);
    }

    static splitWordIntoLinesJoinMaxed(toWord: string, toLanguage: ILanguages, sepatator = '<br>'): string {
        const max = 4; // Max number of returned lines
        let words = toWord.split(' / ');
        words = words.map(word => word.replace(/__START_NO_SHOW__(.*?)__END_NO_SHOW__/g, '$1').trim());
        if (CONSTANTS.RTL_LANGUAGES.includes(toLanguage)) {
            words = words.reverse();
        }
        return words.slice(0, max - 1).join(sepatator);
    }

    static splitWordIntoLines(toWord: string): string[] {
        const max = 4; // Max number of returned lines
        return toWord.split(' / ').slice(0, max);
    }

    /**
     * Checks the `expiryDate` property - if the property doesn't exist, it returns false
     * @param video the video
     * @returns true if the vide is already expires
     */
    static isVideoExpired(video: IVideo): boolean {
        if (!video.expiryDate) {
            return false;
        }
        return moment().isAfter(video.expiryDate);
    }

    static canEnableOrWatchPremiumVideo(video: IVideo, isSubjectsPage: boolean = false, authenticatedUser: IUser, userLicense: ILicense) {
        const hasDurationInSeconds = !!video.duration;
        let maxDurationForFreeInSeconds, maxDurationForFreeInMinutes;
        const kidsCategoriesIds: string[] = CONSTANTS.KIDS_CATEGORIES_IDS as unknown as string[];
        if (video.tags && video.tags.includes('kids') || kidsCategoriesIds.includes(video.cat_id)) {
            maxDurationForFreeInSeconds = CONSTANTS.MAX_DURATION_VIDEOS_FOR_FREE_IN_SECONDS_KIDS_CONTENT;
            maxDurationForFreeInMinutes = CONSTANTS.MAX_DURATION_VIDEOS_FOR_FREE_IN_MINUTES_KIDS_CONTENT;
        } else {
            maxDurationForFreeInSeconds = CONSTANTS.MAX_DURATION_VIDEOS_FOR_FREE_IN_SECONDS_REGULAR_CONTENT;
            maxDurationForFreeInMinutes = CONSTANTS.MAX_DURATION_VIDEOS_FOR_FREE_IN_MINUTES_REGULAR_CONTENT;
        }
        const isDurationLessThanThreeMinutes = (hasDurationInSeconds && video.duration <= maxDurationForFreeInSeconds) ||
            (!hasDurationInSeconds && video.durMinutes <= maxDurationForFreeInMinutes);

        const canFreeUserWatchVideo = video['isAvailableForFreeUser'] || (isDurationLessThanThreeMinutes && !isSubjectsPage);

        if (userLicense && userLicense.type === LicenseType.FREE && video.websource_id === 'lt1') {
            return false;
        }
        if (!authenticatedUser && video.websource_id === 'orftvthek') {
            return true;
        }
        if (authenticatedUser && (userLicense.type === LicenseType.FULL || canFreeUserWatchVideo)) {
            return true;
        } else if (authenticatedUser && userLicense.type === LicenseType.FREE && canFreeUserWatchVideo) {
            return true;
        } else if (video['isVideoOfTheDay']) {
            return true;
        }

        return false;
    }


}

