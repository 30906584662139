import { NgModule, LOCALE_ID } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { RouteReuseStrategy } from '@angular/router';
import localeDE from '@angular/common/locales/de';
import { registerLocaleData } from '@angular/common';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { Ionic4DatepickerModule } from '@logisticinfotech/ionic4-datepicker';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AppData } from './services/app-data.service';
import { VideoCatalogPage } from './pages/video-catalog/video-catalog-page';
import { VideoCatalogTagPage } from './pages/video-catalog/video-catalog-tag-page';
import { LearningCardsPage } from './pages/learning-cards/learning-cards-page';
import { VideoPlayerPage, VideoPlayerPopoverPage } from './pages/video-player/video-player-page';
import { SettingsPage } from './pages/settings/settings-page';
import { WordCatalogPage } from './pages/word-catalog/word-catalog-page';
import { WordCatalogCardsPage } from './pages/word-catalog-cards/word-catalog-cards-page';
import { FlashCardsPage } from './pages/flash-cards/flash-cards-page';
import { UserDetailsPromptPage } from './pages/user-details-prompt/user-details-prompt-page';
import { ClassCreatePage } from './pages/class-create/class-create-page';
import { EducatorLoginPage } from './pages/educator-login/educator-login-page';
import { StudentLoginPage } from './pages/student-login/student-login-page';
import { ClassDetailsEducatorPage } from './pages/class-details-educator/class-details-educator-page';
import { ClassDetailsStudentPage } from './pages/class-details-student/class-details-student-page';
import { WorksheetSelectPage } from './pages/worksheet-select/worksheet-select-page';
import { WorksheetUploadPage } from './pages/worksheet-upload/worksheet-upload-page';
import { JwtTokenHeaderInterceptor } from './utils/jwtTokenHeader.interceptor';
import { LanguageHeaderInterceptor } from './utils/languageHeader.interceptor';
import { AuthErrorInterceptor } from './utils/authError.interceptor';
import { AboutPage, LicensesPage } from './pages/about/about-page';
import { TranslationCorrectionPage } from './pages/translation-correction/translation-correction-page';
import { TutorialPage } from './pages/tutorial/tutorial-page';
import { ClassSelectUserPage } from './pages/class-select-user/class-select-user-page';
import { environment } from '../environments/environment';
import { ServiceWorkerModule } from '@angular/service-worker';
import { VideoPlayerEmbedPage } from './pages/video-player-embed/video-player-embed-page';
import { SubjectsCatalogPage } from './pages/subjects-catalog/subjects-catalog-page';
import { HelpPage } from './pages/help/help-page';
import { EditProfilePage } from './pages/edit-profile/edit-profile-page';
import { InviteEducatorsPage } from './pages/invite-educators-page/invite-educators-page';
import { TutorialGenericPage } from './pages/tutorial-generic/tutorial-generic-page';
import { VideoPlayerEmbedPopoverPage } from './pages/video-player/video-player-embed-popover.page';
import { WordCatalogGroupsPage } from './pages/word-catalog-groups/word-catalog-groups-page';
import { FlashCardsGroupsPage } from './pages/flash-cards-groups/flash-cards-groups-page';
import { ErrorPage } from './pages/error/error-page';
import { ActionMenuPopoverPage } from './utils/action-menu-popover.page';
import { VideoPlayerNextVideoPopoverPage } from './pages/video-player/video-player-nextVideo-popover.page';
import { TermsOfUsePage } from './pages/about/terms-of-use-page';
import { VideoCatalogCardComponent } from './components/video-catalog-card/video-catalog-card.component';
import { VideoCatalogMyVideosPage } from './pages/video-catalog/video-catalog-my-videos-page';
import { SharedModule } from './shared.module';
import { VideoCatalogExpiresFlap } from './components/video-catalog-expires-flap/video-catalog-expires-flap.component';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { VideoPlayerSettings } from './components/video-player-settings/video-player-settings.component';
import { VideoPlayerSettingsPreviewAnimation } from './components/video-player-settings-preview-animation/video-player-settings-preview-animation.component';
import { VideoOverlayIntro2 } from './components/video-overlay-intro2/video-overlay-intro2.component';
import { VideoPlayerScreenLock } from './components/video-player-screen-lock/video-player-screen-lock.component';
import { VideoPlayerEducatorOverlayComponent } from './components/video-player-educator-overlay/video-player-educator-overlay.component';
import { VideoPlayerTaskOverlayComponent } from './components/video-player-task-overlay/video-player-task-overlay.component';
import { VideoPlayerEditQuestionPage } from './pages/video-player-educator/video-player-edit-question/video-player-edit-question.page';
import { VideoPlayerAddQuestionPage } from './pages/video-player-educator/video-player-add-question/video-player-add-question.page';
import { ClipTasksStatisticsPage } from './pages/clip-tasks-statistics/clip-tasks-statistics.page';
import { VideoPlayerLeaderboardOverlay } from './components/video-player-leaderboard-overlay/video-player-leaderboard-overlay.component';
import { ClassLeaderboardPage } from './pages/class-leaderboard/class-leaderboard.page';
import { SearchableSelectComponent } from './components/searchable-select/searchable-select.component';
import { OnboardingPage } from './pages/onboarding/onboarding-page';
import { IonicSelectableModule } from 'ionic-selectable';
import { EducatorLoginV2Page } from './pages/educator-login-v2/educator-login-v2-page';
import { ConfirmUserPage } from './pages/confirm-user/confirm-user-page';
import { ForgotPasswordPage } from './pages/forgot-password/forgot-password-page';
import { ShowHidePasswordComponent } from './components/show-hide-password/show-hide-password.component';
import { EditPasswordProfilePage } from './pages/edit-password/edit-password-profile-page';
import { OnboardingUnverifiedPage } from './pages/onboarding-unverified/onboarding-unverified-page';
import { InviteAdminPage } from './pages/invite-admin/invite-admin-page';
import { CreateClassGuard } from './utils/createClass.guard';
import {
    CreateClassPaidFeaturePopupComponent
} from './components/create-class-paid-feature-popup/create-class-paid-feature-popup.component';
import {
    CreateClassCompleteOnboardingModalComponent
} from './components/create-class-complete-onboarding-modal/create-class-complete-onboarding-modal.component';
import {
    ClassDetailsListVideosStudentPage
} from './pages/class-details-list-videos-student/class-details-list-videos-student-page';
import { OnboardingStudentPage } from './pages/onboarding-student/onboarding-student-page';
import {
    VideoCatalogOnlyPremiumFlapComponent
} from './components/video-catalog-only-premium-flap/video-catalog-only-premium-flap.component';
import {
    EmbedShareModalComponent
} from './components/embed-share-modal/embed-share-modal.component';
import { ReachAll } from './pages/reach-all/reach-all';
import { ClassCreateNewPage } from './pages/class-create-new/class-create-new-page';

// The translate loader needs to know where to load i18n files
// in Ionic's static asset pipeline.
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

registerLocaleData(localeDE);

export function localeFactory(appData: AppData) {
    return appData.getLanguage();
}

@NgModule({
    declarations: [
        AppComponent,
        VideoCatalogPage,
        VideoCatalogTagPage,
        LearningCardsPage,
        VideoPlayerPage,
        VideoPlayerPopoverPage,
        SettingsPage,
        OnboardingPage,
        OnboardingUnverifiedPage,
        WordCatalogPage,
        WordCatalogCardsPage,
        FlashCardsPage,
        UserDetailsPromptPage,
        ClassCreatePage,
        EducatorLoginPage,
        EducatorLoginV2Page,
        StudentLoginPage,
        ClassDetailsEducatorPage,
        ClassDetailsStudentPage,
        ClassDetailsListVideosStudentPage,
        WorksheetSelectPage,
        WorksheetUploadPage,
        AboutPage,
        TranslationCorrectionPage,
        TutorialPage,
        ClassSelectUserPage,
        VideoPlayerEmbedPage,
        LicensesPage,
        SubjectsCatalogPage,
        HelpPage,
        EditProfilePage,
        InviteAdminPage,
        InviteEducatorsPage,
        TutorialGenericPage,
        VideoPlayerEmbedPopoverPage,
        WordCatalogGroupsPage,
        FlashCardsGroupsPage,
        ErrorPage,
        ActionMenuPopoverPage,
        VideoPlayerNextVideoPopoverPage,
        TermsOfUsePage,
        VideoCatalogCardComponent,
        VideoCatalogExpiresFlap,
        VideoCatalogMyVideosPage,
        VideoPlayerSettings,
        VideoPlayerSettingsPreviewAnimation,
        VideoOverlayIntro2,
        VideoPlayerScreenLock,
        VideoPlayerEducatorOverlayComponent,
        VideoPlayerTaskOverlayComponent,
        VideoPlayerEditQuestionPage,
        VideoPlayerAddQuestionPage,
        ClipTasksStatisticsPage,
        VideoPlayerLeaderboardOverlay,
        ClassLeaderboardPage,
        SearchableSelectComponent,
        ConfirmUserPage,
        ForgotPasswordPage,
        ShowHidePasswordComponent,
        EditPasswordProfilePage,
        CreateClassPaidFeaturePopupComponent,
        CreateClassCompleteOnboardingModalComponent,
        OnboardingStudentPage,
        VideoCatalogOnlyPremiumFlapComponent,
        EmbedShareModalComponent,
        ReachAll,
        ClassCreateNewPage
    ],
    imports: [
        SharedModule,
        BrowserModule,
        HttpClientModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        LoggerModule.forRoot({
            level: environment.production ? NgxLoggerLevel.WARN : NgxLoggerLevel.DEBUG,
            serverLogLevel: NgxLoggerLevel.OFF,
            serverLoggingUrl: '/api/logs',
            enableSourceMaps: true,
            timestampFormat: 'HH:mm:ss.SSS'
        }),
        Ionic4DatepickerModule,
        // There seems to be an issue with this - see https://stackoverflow.com/questions/53329924/angular-7-service-worker-not-registered
        // So instead it's done in main.ts instead
        // ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
        IonicSelectableModule
    ],
    providers: [
        {
            provide: LOCALE_ID,
            deps: [AppData],
            useFactory: localeFactory
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JwtTokenHeaderInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LanguageHeaderInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthErrorInterceptor,
            multi: true
        },
        {
            provide: RouteReuseStrategy,
            useClass: IonicRouteStrategy
        },
        ScreenOrientation,
        CreateClassGuard,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    bootstrap: [AppComponent]
    // exports: [TranslateModule]
})
export class AppModule {
}
