// angular
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

// services
import { Utils } from '../../utils/utils';
import { AppData } from 'src/app/services/app-data.service';
import { AnalyticsService } from 'src/app/services/analytics.service';

// constants
import { Constants } from '../../app.constants';

// libraries
import { TranslateService } from '@ngx-translate/core';

import { IWebsource } from '../../interfaces/IWebsource';
import { ILanguage } from 'src/app/interfaces/ILanguage';
import { WebsourcesApi } from 'src/app/services/api/websources-api.service';
import { UiUtils } from 'src/app/services/ui-utils.service';
import { AbstractRootMenuPage } from 'src/app/utils/abstract-root-menu-page';
import { Platform } from '@ionic/angular';
import { NGXLogger } from 'ngx-logger';

@Component({
    selector: 'app-settings',
    templateUrl: 'settings-page.html'
})
export class SettingsPage extends AbstractRootMenuPage implements OnInit {
    public systemLanguageName: string;
    public settingsModel = {};
    public videoSourceValues: IWebsource[] = [];
    public languages: ILanguage[] = [];
    private languageChangeTimestamp = 0;

    constructor(
        public appData: AppData,
        public constants: Constants,
        public translate: TranslateService,
        public analytics: AnalyticsService,
        public websourcesApi: WebsourcesApi,
        private titleService: Title,
        private uiUtils: UiUtils,
        protected plt: Platform,
        private logger: NGXLogger) {
        super(plt);
    }

    ionViewWillEnter() {
        super.ionViewWillEnter();
        this.appData.activePage = 'settings';
    }

    ionViewWillLeave() {
        super.ionViewWillLeave();
    }

    ngOnInit() {
        this.titleService.setTitle('Settings | uugot.it');
        this.analytics.trackPageView('settings-page');

        this.translate.get('lang_' + Utils.getSystemLanguage()).subscribe((res: string) => {
            this.systemLanguageName = res;
        });
        // this.systemLanguageName = this.translate.instant('lang_' + this.appData.getSystemLanguage());

        this.settingsModel[this.constants.pref.LANGUAGE] = this.appData.getPreferenceString(this.constants.pref.LANGUAGE, this.appData.constants.DefaultLanguage);
        this.settingsModel[this.constants.pref.VIDEO_QUALITY] = this.appData.getPreferenceString(this.constants.pref.VIDEO_QUALITY, this.constants.DefaultVideoQuality);
        this.settingsModel[this.constants.pref.TRANSLATION_TYPE] = this.appData.getPreferenceString(this.constants.pref.TRANSLATION_TYPE, this.appData.constants.DefaultTranslationType);
        this.settingsModel[this.constants.pref.TRANSLATION_LANG] = this.appData.getPreferenceString(this.constants.pref.TRANSLATION_LANG, this.appData.constants.DefaultTranslationLang);
        this.settingsModel[this.constants.pref.VIDEO_SOURCE] = this.appData.getPreferenceString(this.constants.pref.VIDEO_SOURCE, this.appData.constants.DefaultVideoSource);

        this.logger.debug('videoQuality=%s language=%s', this.settingsModel[this.constants.pref.VIDEO_QUALITY], this.settingsModel[this.constants.pref.LANGUAGE]);

        // let email = this.appData.getPreferenceString(this.constants.pref.USER_EMAIL);
        // let values = this.translate.instant(['source_orftvthek', 'source_ndr', 'source_daserste']);
        // if(Utils.isInternalEmailAdress(email)) {
        //   this.videoSourceValues = [
        //     { val: this.constants.VIDEO_SOURCE.ORFTVTHEK, name: values.source_orftvthek },
        //     { val: this.constants.VIDEO_SOURCE.NDR, name: values.source_ndr },
        //     { val: this.constants.VIDEO_SOURCE.DASERSTE, name: values.source_daserste }
        //   ];
        // } else {
        //   // For test users we only allow ORF
        //   this.videoSourceValues = [ { id: this.constants.VIDEO_SOURCE.ORFTVTHEK, name: values.source_orftvthek } ];
        // }

        const isLoggedIn = this.appData.isLoggedIn();
        const isEducator = this.appData.isEducator();

        // If user is logged in, allow translation languages in license
        this.languages = this.appData.getSubtitleLanguagesForLoggedInUser();

        this.updateLanguageOptions();

        this.videoSourceValues = this.appData.videoSources.filter(source => {
            if (source.visibility === 0) {
                return false;
            }
            const isUugotitTV = !source.isScooling;
            if (isLoggedIn) {
                // Logged in users
                // Only license web sources are allowed
                // For students only non-sCOOLing web sources
                if (this.appData.userLicense.websources.includes(source.id) && (isEducator || isUugotitTV)) {
                    return true;
                }
            } else {
                // Only ORF allowed for guests
                // if (this.constants.VideoSourcesEnabledForNonEducators.includes(source.id)) {

                // Only non-sCOOLing sources are allowed
                return isUugotitTV;
            }
            return false;
        });

        // if (!Utils.isInternalEmailAdress(email)) {
        if (window.location.hostname === 'orf.uugot.it') {
            // This is just for the purpose of beta testing (only show ORF)
            this.videoSourceValues = this.videoSourceValues.filter((source: IWebsource) => source.id === this.constants.VIDEO_SOURCE.ORFTVTHEK);
        } else {
            // Don't show the "all sources" https://gitlab.com/uugotit/webapp/issues/100
            // this.videoSourceValues.unshift({ id: this.constants.VIDEO_SOURCE.ALL, name: this.translate.instant('source_all') });
        }
    }

    updateLanguageOptions() {
        this.languages.forEach(lang => this.uiUtils.setDisplayNameOfLanguage(lang));
        this.languages = this.languages.sort((a, b) => a['displayName'].localeCompare(b['displayName']));
    }

    /**
     * Called when a setting is changed.
     */
    onChangeValue(prefKey: string) {
        const languageChangeDiff = new Date().getTime() - this.languageChangeTimestamp;
        if (languageChangeDiff < 500) {
            this.logger.debug('onChangeValue diff is too little: %s', languageChangeDiff);
            // An onChangeValue() is triggered by the app language change, so we need to ignore value changes
            return;
        }
        const value = this.settingsModel[prefKey];
        this.logger.debug('onChangeValue %s: %s', prefKey, value);

        this.appData.savePreferenceString(prefKey, value);

        if (prefKey === this.constants.pref.VIDEO_SOURCE) {
            this.appData.forceReloadVideos();
        } else if (prefKey === this.constants.pref.TRANSLATION_LANG) {
            this.appData.forceReloadVideos();
        } else if (prefKey === this.constants.pref.LANGUAGE) {
            if (value === 'system') {
                // the lang to use, if the lang isn't available, it will use the current loader to get them
                this.translate.use(Utils.getSystemLanguage());
            } else {
                this.translate.use(value);
            }

            this.languageChangeTimestamp = new Date().getTime();

            // We need to update the <ion-select>s
            const transLangValue = this.settingsModel[this.constants.pref.TRANSLATION_LANG];
            const qualityValue = this.settingsModel[this.constants.pref.VIDEO_QUALITY];
            this.settingsModel[this.constants.pref.TRANSLATION_LANG] = '';
            this.settingsModel[this.constants.pref.VIDEO_QUALITY] = '';
            setTimeout(() => {
                this.updateLanguageOptions();
                this.settingsModel[this.constants.pref.TRANSLATION_LANG] = transLangValue;
                this.settingsModel[this.constants.pref.VIDEO_QUALITY] = qualityValue;
            }, 100);

        }
    }
}
