<ion-content
    *ngIf="failedServerResponse"
    class="video-overlay-center-middle deactivated-bg"
    [class.mobile-landscape-videoplayer]="true" >
    <div class="centered-content">
        <ion-img src="assets/img/yuyu_yellow_sad2.svg" class="deactivated-img"></ion-img>
        <p class="deactivated" [innerHTML]="'reachall_video_deactivated' | translate"></p>
    </div>
</ion-content>
<ion-content
    *ngIf="!failedServerResponse && !loadVideoPlayer"
    [class.mobile-landscape-videoplayer]="true">
    <ion-progress-bar type="indeterminate" *ngIf="showLoadingProgress" class="video-player-progress-bar"></ion-progress-bar>

    <div id="video-container" #videoContainer *ngIf='!loadVideoPlayer'>
        <div class="video-overlay-center-middle" [class.overlay-active]="overlayActive">
            <ion-img id="imageInIframe" class="video-js  vjs-default-skin vjs-big-play-centered" *ngIf='imageURL' [src]="imageURL"
                     (click)="loadIframe()"
                     (ionImgDidLoad)="onImageLoad()"
                     (mouseover)="onImageMouseOver()"
                     (mouseout)="onImageMouseOut()">
            </ion-img>
            <div class="overlay" [style.background-color]="'rgba(0, 0, 0, ' + overlayOpacity + ')'"></div>
            <div class="centered-content">
                <div class="video-title">
                    <a  href="" target="_blank">{{videoTitle}}</a>
                </div>
                <ion-img src="assets/img/video-player/play-circle-outline.svg" *ngIf='imageURL' class="video-play-button" (click)="toggleOverlay()"></ion-img>
                <div class="video-info">
                    <ion-icon name="information-circle"></ion-icon>
                    <span [innerHTML]="'video_intro_info' | translate"></span>
                </div>
            </div>
        </div>
        <div id="video-overlay-intro" *ngIf='!loadVideoPlayer'>
            <ion-row class="video-subtitle">
                <ion-col class="video-subtitle-flex-center" size="9">
                </ion-col>
            </ion-row>
        </div>
    </div>
</ion-content>
<!--<app-video-embed-player-->
<!--    *ngIf='video_id'-->
<!--    [video_id]='video_id'-->
<!--&gt;-->

<!--</app-video-embed-player>-->
<iframe
    *ngIf='loadVideoPlayer'
    class=""
    [src]="safeUrl"
    (load)="onIframeLoad()"
    allowfullscreen=true>
</iframe>
