<ion-header>
    <ion-toolbar [color]="appData.isEducator() ? 'blue' : appData.isStudent() ? 'green' : 'secondary'">
        <ion-buttons slot="start">
            <ion-menu-toggle>
                <ion-button>
                    <ion-icon slot="icon-only" name="menu"></ion-icon>
                </ion-button>
            </ion-menu-toggle>
        </ion-buttons>
        <ion-title *ngIf="currentState !== State.logged_in">
            {{ 'menu_login_scooling' | translate }}
        </ion-title>
        <ion-title *ngIf="currentState === State.logged_in">
            {{ 'menu_account' | translate }}
        </ion-title>
        <ion-buttons *ngIf="currentState === State.logged_in"  slot="end">
            <ion-button [color]="appData.isEducator() ? 'white' : appData.isStudent() ? 'white' : 'primary'" (click)="logout()">
                <ion-icon name="exit" slot="start"></ion-icon>
                {{ 'log_out' | translate }}
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content class="ion-padding ion-margin-bottom ion-text-center">
    <div class="page-container">
        <div>
            <img class="logoWithState"
                 src="assets/icon/{{ currentState === State.home || currentState === State.logged_in ? 'launcher-icon-3x.png' : 'yuyu_yellow_sad2.png' }}" />

            <h2 class="header1">{{ mainText | translate }}</h2>

            <p *ngIf="currentState === State.logged_in && appData.authenticatedUser" class="subHeader1 content-infotext ion-text-capitalize">Role: {{appData.authenticatedUser.customer_role}}</p>
            <p class="subHeader1 content-infotext">{{ subHeaderText | translate }}</p>
        </div>
        <div *ngIf="currentState === State.logged_in && appData.authenticatedUser">
            <div>
              {{ 'logged_in_as' | translate }}
                <strong>
                {{ appData.isStudent() ? appData.authenticatedUser.first_name + ' ' + appData.authenticatedUser.last_name : appData.authenticatedUser.email }}
              </strong>
            </div>
            <div class='ion-margin-top'>
                <ion-text class='ion-margin-top'>
                    {{getDisplayNameForUser()}}
                </ion-text>
            </div>
            <p class="ion-no-padding">
                <ion-button [color]="appData.isEducator() ? 'blue' : appData.isStudent() ? 'green' : 'primary'"
                            (click)="buttonEditProfilePageClicked()">
                    <ion-icon name="create" slot="start"></ion-icon>
                    {{ 'edit_profile' | translate }}
                </ion-button>
            </p>
            <div class="ion-padding" *ngIf="appData.authenticatedUser.customer_role === 'admin' && customer?.license.type === 'free'">
                <ion-list>
                    <ion-list-header class="ion-no-padding ion-text-start">
                        <ion-label>
                            <h2><b>{{'btn_licenses' | translate}}</b></h2>
                        </ion-label>
                    </ion-list-header>
                    <ion-item *ngIf="loadingGroups">
                        <ion-skeleton-text animated width="100%">
                            <ion-label>Loading...</ion-label>
                            <ion-label class="ion-text-capitalize">Loading...</ion-label>
                            <ion-label class="ion-text-capitalize">Loading...</ion-label>
                            <a></a>
                        </ion-skeleton-text>
                    </ion-item>
                    <ion-item class='ion-no-padding'  *ngIf='!loadingGroups'>
                        <ion-label >{{totalConsumedLicenses}}/30 ({{'free_user_limited'| translate}})</ion-label>
                        <a href="https://www.uugot.it/sba/" target="_blank" rel="noopener noreferrer">
                            <ion-button color='blue' class="ion-no-margin">{{'buy_licenses'| translate}}</ion-button>
                        </a>
                    </ion-item>
                    <ion-item lines='none' *ngIf='!loadingGroups'>
                        <ion-text class='buy_licenses_title'>
                            *{{'buy_licenses_title' | translate}}
                        </ion-text>
                    </ion-item>
                </ion-list>
            </div>

            <div class="ion-padding" *ngIf="appData.authenticatedUser.customer_role === 'admin'">
                <ion-list>
                    <ion-list-header class="ion-no-padding ion-text-start">
                        <ion-label><h2><b>{{'class_name_admin' | translate}}</b></h2></ion-label>
                    </ion-list-header>
                    <ion-item *ngIf="loadingGroups">
                        <ion-skeleton-text animated width="100%">
                            <ion-label>Loading...</ion-label>
                            <ion-label class="ion-text-capitalize">Loading...</ion-label>
                            <ion-label class="ion-text-capitalize">Loading...</ion-label>
                            <a></a>
                        </ion-skeleton-text>
                    </ion-item>
                    <ng-container *ngIf="!loadingGroups">
                        <ion-item *ngFor="let group of appData.educatorAdminGroups; let i = index">
                            <ion-label>{{group.students_count}}</ion-label>
                            <ion-label class="ion-text-capitalize">
                                {{ group.class_name }}
                            </ion-label>
                            <ion-label class="">
                                {{ getDisplayName(group.main_educator) }}
                            </ion-label>
                            <a (click)="toggleInfo($event, i)" class="info-link" [class.selected]="selectedClassId === i">
                                <ion-icon name="information-circle-outline" size="large" [class.selected]="selectedClassId === i"></ion-icon>
                            </a>
                        </ion-item>
                    </ng-container>
                </ion-list>
            </div>





            <div class="ion-no-padding" #classInfo>
                <div *ngIf="showGroupInfo">
                    <div class="ion-text-start">
                        <h3><a [routerLink]="['/educator-class/details', selectedClass.class_id]">{{ 'class_name_admin' | translate }} {{ selectedClass.class_name }}</a></h3>
                        <p>Licenzen: {{selectedClass.students_count}}</p>
                    </div>
                    <div class="ion-text-start ion-text-capitalize">
                        <h3>{{ 'main_teacher' | translate }}:</h3>
                        <p>
                            {{ selectedClass.main_educator?.first_name }}
                            {{ selectedClass.main_educator?.last_name }}
                            <span *ngIf="getSubjectsStringFromEducator(selectedClass.main_educator)">-</span>
                            {{ getSubjectsStringFromEducator(selectedClass.main_educator) }}
                        </p>
                    </div>
                    <div class="ion-text-start" *ngIf='getTeamEducatorsListFromSelectedClass().length > 0'>
                        <h3 class='ion-no-padding ion-no-margin'>{{ 'team_teachers' | translate }}:</h3>
                        <ion-list class='ion-no-margin'>
                            <ion-item *ngFor="let educator of getTeamEducatorsListFromSelectedClass(); let i = index">
                                <ion-label class="ion-no-margin">
                                    <h2 class='ion-text-capitalize' *ngIf='educator.edId.first_name && educator.edId.last_name; else h2WithEmail' >
                                        {{ educator.edId?.first_name }}
                                        {{ educator.edId?.last_name }}
                                    </h2>
                                    <ng-template #h2WithEmail>>
                                        <h2>
                                            {{educator.edId.email}}
                                        </h2>
                                    </ng-template>
                                    <p *ngIf='educator.edId.first_name'>{{educator.edId.email}}</p>
                                    <p>{{ getSubjectsStringFromEducator(educator.edId) }}</p>
                                </ion-label>
                                <a class="info-link">
                                    <ion-icon name="chevron-up-outline" (click)='upgradeToMainEducator(selectedClass, educator.edId)' size="large"></ion-icon>
                                </a>
                            </ion-item>
                        </ion-list>
                    </div>
                </div>
            </div>


            <p class="ion-margin-top" *ngIf='currentState === State.logged_in && appData.authenticatedUser.customer_role === "admin" && appData.isEducator()'>
                <ion-button [color]="'blue'"
                            (click)='onInviteAdminClicked()'
                >
                    <ion-icon name="person-add-outline" slot="start"></ion-icon>
                    {{'INVITE admin' | translate}}
                </ion-button>
                <ion-button [color]="appData.isEducator() ? 'blue' : appData.isStudent() ? 'green' : 'primary'"
                            (click)="buttonEditProfilePasswordClicked()">
                    <ion-icon name="create" slot="start"></ion-icon>
                    {{'change_my_password_button' | translate}}
                </ion-button>
            </p>


            <div class="ion-padding" *ngIf='currentState === State.logged_in && appData.authenticatedUser.customer_role !== "admin" && appData.isEducator()'>
                <ion-segment [(ngModel)]="accountSegment" color="blue">
                    <ion-segment-button value="profile">
                        <ion-label>{{ 'my_profile' | translate }}</ion-label>
                    </ion-segment-button>
<!--                    <ion-segment-button value="admin" *ngIf="appData.authenticatedUser.customer_role === 'admin'" >-->
<!--                        <ion-label>Admin</ion-label>-->
<!--                    </ion-segment-button>-->
<!--                    <ion-segment-button value="groups" *ngIf="appData.authenticatedUser.customer_role === 'admin'" >-->
<!--                        <ion-label>Classes</ion-label>-->
<!--                    </ion-segment-button>-->

                </ion-segment>
            </div>
            <div [ngSwitch]="accountSegment" *ngIf='appData.authenticatedUser.customer_role !== "admin" && appData.isEducator()'>
                <div *ngSwitchCase="'profile'">
                    <ion-list lines="full" class="ion-no-margin ion-no-padding" >
                        <ion-item>
                            <ion-label position="floating">{{'email' | translate}} <ion-text>*</ion-text>
                            </ion-label>
                            <ion-input disabled='' [value]='appData.authenticatedUser.email' type="text" enterkeyhint="next" inputmode="text" autocapitalize="sentences" maxlength="50" readonly='' ></ion-input>
                        </ion-item>

                        <ion-item>
                            <ion-label position="floating">{{'first_name' | translate}} <ion-text>*</ion-text>
                            </ion-label>
                            <ion-input disabled='' [value]='appData.authenticatedUser?.first_name' type="text" enterkeyhint="next" inputmode="text" autocapitalize="sentences" maxlength="50" readonly='' ></ion-input>
                        </ion-item>

                        <ion-item>
                            <ion-label position="floating">{{'last_name' | translate}} <ion-text>*</ion-text></ion-label>
                            <ion-input disabled=''  [value]='appData.authenticatedUser?.last_name' type="text" enterkeyhint="next" inputmode="text" autocapitalize="sentences" maxlength="50" readonly='' ></ion-input>
                        </ion-item>

                        <ion-item>
                            <ion-label position="floating">{{'school' | translate}}
                            </ion-label>
                            <ion-input disabled=''  [value]='appData.authenticatedUser?.schoolName' type="text" enterkeyhint="next" inputmode="text" autocapitalize="sentences" maxlength="50" readonly='' ></ion-input>
                        </ion-item>
                        <ion-item>
                            <ion-label position="floating">{{'city' | translate}} <ion-text></ion-text>
                            </ion-label>
                            <ion-input disabled='' [value]='appData.authenticatedUser?.city' type="text" enterkeyhint="next" inputmode="text" autocapitalize="sentences" maxlength="50" readonly='' ></ion-input>
                        </ion-item>
                    </ion-list>


                    <p class="ion-margin-top" *ngIf='appData.isEducator()'>
                        <ion-button [color]="appData.isEducator() ? 'blue' : appData.isStudent() ? 'green' : 'primary'"
                                    (click)="buttonEditProfilePasswordClicked()">
                            <ion-icon name="create" slot="start"></ion-icon>
                            {{'change_my_password_button' | translate}}
                        </ion-button>
                    </p>

                    <p class="ion-margin-top">
                        <ion-button *ngIf="appData.isEducator()" [color]="appData.isEducator() ? 'blue' : appData.isStudent() ? 'green' : 'primary'"
                                    (click)="buttonInviteEducatorsClicked()">
                            <ion-icon name="person-add" slot="start"></ion-icon>
                            {{ 'invite_colleagues' | translate }}
                        </ion-button>
                    </p>
                </div>
                <div *ngSwitchCase="'groups'">
                    <ion-list>
                        <ion-card  color='primary' *ngFor="let group of appData.educatorAdminGroups; let i = index">
                            <ion-card-header>
                                <ion-card-title class='ion-text-capitalize'>{{ group.class_name }}</ion-card-title>
                                <ion-card-subtitle>{{'main_teacher' | translate}}: {{ group.main_educator.last_name }} {{ group.main_educator.first_name }}</ion-card-subtitle>
                            </ion-card-header>
                            <ion-card-content>
                            </ion-card-content>
                            <ion-button color='secondary' fill="clear">{{'change_main_teacher' | translate}}</ion-button>
                            <ion-button color='secondary' fill="clear">{{ 'team_teachers' | translate}}</ion-button>
                        </ion-card>
                    </ion-list>
                </div>









                <!--                <div *ngSwitchCase="'invites'">-->
<!--                    <ng-container *ngIf="loadingInvites; else content">-->
<!--                        &lt;!&ndash; Loading spinner &ndash;&gt;-->
<!--                        <ion-spinner></ion-spinner>-->
<!--                    </ng-container>-->
<!--                    <ng-template #content>-->
<!--                        <ion-grid *ngIf="appData.educatorInvitesToGroups && appData.educatorInvitesToGroups.length > 0">-->
<!--                            &lt;!&ndash; Existing invites &ndash;&gt;-->
<!--                            <ion-row>-->
<!--                                <ion-col><b>Class name</b></ion-col>-->
<!--                                <ion-col><b>Invited by</b></ion-col>-->
<!--                                <ion-col><b>State</b></ion-col>-->
<!--                                <ion-col><b>Action</b></ion-col>-->
<!--                            </ion-row>-->
<!--                            <ion-row *ngFor="let invite of appData.educatorInvitesToGroups">-->
<!--                                <ion-col>-->
<!--                                    {{invite.group.name}}-->
<!--                                </ion-col>-->
<!--                                <ion-col>-->
<!--                                    {{invite.invitedBy.first_name}} {{invite.invitedBy.last_name}}-->
<!--                                </ion-col>-->
<!--                                <ion-col class=''>-->
<!--                                    {{invite.state}}-->
<!--                                </ion-col>-->
<!--                                <ion-col>-->
<!--                                    <ng-container *ngIf="invite.state.toLowerCase() !== 'accepted' && invite.state.toLowerCase() !== 'rejected'; else link">-->
<!--                                        <ion-button (click)="acceptInvite(invite)" [disabled]='submitting' color="success" size="small">-->
<!--                                            <ion-icon slot="icon-only" name="checkmark-outline"></ion-icon>-->
<!--                                        </ion-button>-->
<!--                                        <ion-button (click)="rejectInvite(invite)" [disabled]='submitting' color="danger" size="small">-->
<!--                                            <ion-icon slot="icon-only" name="close-outline"></ion-icon>-->
<!--                                        </ion-button>-->
<!--                                    </ng-container>-->
<!--                                    <ng-template #link>-->
<!--                                        <a *ngIf="invite.state.toLowerCase()  === 'accepted'" [routerLink]="['/educator-class/details', invite.group._id]">Go to the class</a>-->
<!--                                        <span *ngIf="invite.state.toLowerCase() === 'rejected'">Invite rejected</span>-->
<!--                                    </ng-template>-->
<!--                                </ion-col>-->
<!--                            </ion-row>-->
<!--                        </ion-grid>-->
<!--                        <div *ngIf="appData.educatorInvitesToGroups && appData.educatorInvitesToGroups.length === 0" class="no-invites">-->
<!--                            &lt;!&ndash; No pending invites &ndash;&gt;-->
<!--                            <ion-icon name="information-circle-outline"></ion-icon>-->
<!--                            <p>No pending class invites</p>-->
<!--                        </div>-->
<!--                    </ng-template>-->
<!--                </div>-->
            </div>

            <div class="ion-margin-top ion-margin-bottom" *ngIf="appData.isStudent()">
                {{ 'your_classes' | translate }}:
                <div *ngFor="let g of appData.groupsForUser" class="ion-margin-top">
                    <strong>
                        <a routerLink="/student-class/details/{{g._id}}" routerDirection="root">{{g.name}}</a>
                        <span class="class-access-code" title="{{'code' | translate}}">{{g.access_code}}</span>
                    </strong>
                </div>
                <div class="ion-margin-top" style="margin-top: 30px;">
                    <!-- <a routerLink="/student" routerDirection="root">{{ "add_class" | translate }}</a> -->
                    <ion-button color="green" routerLink="/student" routerDirection="root">
                        <ion-icon name="add" slot="start"></ion-icon>
                        {{ 'add_class' | translate }}
                    </ion-button>
                </div>
            </div>



<!--            <p class="ion-margin-top">-->
<!--                <ion-button [color]="appData.isEducator() ? 'blue' : appData.isStudent() ? 'green' : 'primary'" (click)="logout()">-->
<!--                    <ion-icon name="exit" slot="start"></ion-icon>-->
<!--                    {{ 'log_out' | translate }}-->
<!--                </ion-button>-->
<!--            </p>-->
        </div>
        <div *ngIf="currentState !== State.logged_in">
            <div class="ion-padding-top ion-padding-bottom">
                <ion-segment [(ngModel)]="segment">
                    <ion-segment-button value="password" *ngIf="showLoginWithPassword">
                        <ion-label class='ion-text-uppercase'>{{ "login_with_password" | translate }}</ion-label>
                    </ion-segment-button>
                    <ion-segment-button value="register">
                        <ion-label class='ion-text-uppercase'>{{ "register" | translate }}</ion-label>
                    </ion-segment-button>
                </ion-segment>
            </div>

            <div [ngSwitch]="segment">
                <div *ngSwitchCase="'register'">
                    <form [formGroup]="registerNewEducatorForm" (ngSubmit)="registerWithUserAndPassword()">
                        <ion-grid fixed>
                            <ion-list *ngIf="currentState !== State.legal_error">
                                <ion-item  class="ion-no-padding">
                                    <ion-label position="floating">{{ "email_address" | translate }}</ion-label>
                                    <ion-input
                                        name="email"
                                        type="email"
                                        placeholder="your@email.com"
                                        formControlName='email'
                                        (keydown.enter)="passwordInputRegister.setFocus()"
                                        (keyup.enter)='passwordInputRegister.setFocus()'
                                        ></ion-input>

                                </ion-item>
                                <ion-label class="validator-errors" color="danger"
                                           *ngIf="!registerNewEducatorForm.controls.email.valid && (registerNewEducatorForm.controls['email'].dirty || preauthSubmitAttempt)">
                                    <p *ngIf="registerNewEducatorForm.value.email !== ''">{{'validator_email_not_valid' | translate}}</p>
                                    <p *ngIf="registerNewEducatorForm.value.email === ''">{{'validator_email_required' | translate}}</p>
                                </ion-label>
                                <ion-item  class="ion-no-padding">
                                    <ion-label position="floating">{{ "password" | translate }}</ion-label>
                                    <app-show-hide-password>
                                        <ion-input
                                            #passwordInputRegister
                                            name="password"
                                            type="password"
                                            formControlName='password'
                                            (keydown.enter)="inputConfirmPassword.setFocus()"
                                            required></ion-input>
                                    </app-show-hide-password>
                                </ion-item>
                                <ion-item  class="ion-no-padding">
                                    <ion-label position="floating">{{ "repeat_password" | translate }}</ion-label>
                                    <app-show-hide-password>
                                        <ion-input
                                            #inputConfirmPassword
                                            name="confirm"
                                            type="password"
                                            formControlName='confirm'
                                            required></ion-input>
                                    </app-show-hide-password>
                                </ion-item>
                                <ion-label class="validator-errors" color="danger"
                                           *ngIf="registerNewEducatorForm.controls.confirm.invalid && (registerNewEducatorForm.controls['confirm'].dirty || preauthSubmitAttempt)">
                                    <p *ngIf="registerNewEducatorForm.controls.confirm.errors?.match">{{'validator_password_mismatch' | translate}}</p>
                                </ion-label>
                                <ion-label class="validator-errors" color="danger"
                                           *ngIf=" registerNewEducatorForm.get('confirm').errors?.mismatch">
                                    <p *ngIf="registerNewEducatorForm.controls.confirm.errors.mismatch">{{'validator_password_mismatch' | translate}}</p>
                                </ion-label>
                                <ion-label class="validator-errors" color="danger"
                                           *ngIf="serverErrorMessage">
                                    <p >{{serverErrorMessage}}</p>
                                </ion-label>
                            </ion-list>
                        </ion-grid>
                        <ion-card>
                            <ion-card-content>
                                <ion-list>
                                    <ion-item lines="none">
                                        <ion-icon name="checkmark-circle" [color]="isMinLengthValid ? 'success' : 'danger'"></ion-icon>
                                        <ion-label color="medium">{{'password_characters' | translate}}</ion-label>
                                    </ion-item>
                                    <ion-item lines="none">
                                        <ion-icon name="checkmark-circle" [color]="isPatternValid ? 'success' : 'danger'"></ion-icon>
                                        <ion-label color="medium" class="ion-text-wrap">{{'password_requirements' | translate}}</ion-label>
                                    </ion-item>
                                </ion-list>
                            </ion-card-content>
                        </ion-card>
                        <ion-button color="primary"
                                    expand='block'
                                    class='ion-text-uppercase submit-btn'
                                    (click)="submitRegister()" [disabled]="submitting">
                            {{ currentState == State.legal_error ? ("terms_and_conditions" | translate) :
                            ("register" | translate) }}
                            <ion-spinner class='submit-spinner' *ngIf="submitting"></ion-spinner>
                        </ion-button>
                    </form>
                </div> <!-- END *ngSwitchCase="'register'" -->
                <div *ngSwitchCase="'email'">
                    <p class="email-text">
                        {{ askEmailText | translate }}
                    </p>
                    <form [formGroup]="requestLinkForm" (ngSubmit)="requestLoginLink()">
                        <ion-grid fixed>
                            <ion-list *ngIf="currentState !== State.legal_error">
                                <ion-item class="ion-no-padding">
                                    <ion-label position="floating">{{ "email_address" | translate }}</ion-label>
                                    <ion-input type="email" formControlName="email" #email></ion-input>
                                </ion-item>
                                <ion-label
                                    class="validator-errors"
                                    color="danger"
                                   *ngIf="!requestLinkForm.controls.email.valid && (requestLinkForm.controls['email'].dirty || preauthSubmitAttempt)">
                                    <p *ngIf="requestLinkForm.value.email !== ''">{{'validator_email_not_valid' | translate}}</p>
                                    <p *ngIf="requestLinkForm.value.email === ''">{{'validator_email_required' | translate}}</p>
                                </ion-label>
                            </ion-list>
                        </ion-grid>
                        <ion-button color="primary" type="submit">
                            {{ currentState == State.legal_error ? ("terms_and_conditions" | translate) :
                            ("request_link" | translate) }}
                        </ion-button>
                    </form>
                </div> <!-- END *ngSwitchCase="'email'" -->


                <div *ngSwitchCase="'password'">
                    <p class="email-text">{{ "info_login_with_password" | translate }}</p>
                    <form [formGroup]="loginPasswordForm" (ngSubmit)="loginWithPassword()">
                            <ion-grid>
                                <ion-row *ngIf="currentState != State.legal_error">
                                    <ion-col size='12'>
                                        <ion-item class="ion-no-padding">
                                            <ion-label position="floating">{{ "email_address" | translate }}</ion-label>
                                            <ion-input type="email"
                                                       (keydown.enter)='passwordInput.setFocus()'
                                                       (keyup.enter)="passwordInput.setFocus()"
                                                       tabindex='1'
                                                       formControlName="email"></ion-input>
                                        </ion-item>
                                        <ion-item class="ion-no-padding">
                                            <ion-label position="floating">{{ "password" | translate }}</ion-label>
                                            <app-show-hide-password>
                                                <ion-input
                                                    #passwordInput
                                                    (keydown.enter)="loginWithPassword()"
                                                    (keyup.enter)="loginWithPassword()"
                                                    type="password"
                                                    formControlName="password"></ion-input>
                                            </app-show-hide-password>
                                        </ion-item>
                                        <ion-label class="validator-errors" color="danger"
                                                   *ngIf="!loginPasswordForm.controls.email.valid && (loginPasswordForm.controls['email'].dirty || passwordSubmitAttempt)">
                                            <p *ngIf="loginPasswordForm.value.email !== ''">{{'validator_email_not_valid' | translate}}</p>
                                            <p *ngIf="loginPasswordForm.value.email === ''">{{'validator_email_required' | translate}}</p>
                                        </ion-label>
                                    </ion-col>
                                    <ion-col size='12'>

                                    </ion-col>
                                </ion-row>
                                <ion-row>
                                    <ion-col size='12'>
                                        <ion-button
                                            class='ion-text-uppercase'
                                            color="primary"
                                            #buttonSubmit
                                            expand='block'
                                            type="submit">
                                            {{ currentState == State.legal_error ? ("terms_and_conditions" | translate) :
                                            ("button_log_in" | translate) }}
                                        </ion-button>
                                    </ion-col>
                                </ion-row>
                            </ion-grid>

                    </form>
                </div> <!-- END *ngSwitchCase="'password'" -->
            </div> <!-- END div [ngSwitch]="segment" -->


        </div>
        <div *ngIf="currentState !== State.logged_in" class="info-no-account">
            <p class="content-infotext" >{{'forgot_password_part_one'|translate}} <a href='/forgot-password'>{{'forgot_password_part_two'|translate}}</a>{{ 'forgot_password_part_three' | translate}}</p>
            <p class="content-infotext" [innerHTML]="'info_login_no_account' | translate">
            </p>
            <p class="content-infotext" [innerHTML]="'info_login_private_user' | translate">
            </p>
        </div>
    </div>
</ion-content>
