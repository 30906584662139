// angular
import { Component, OnInit } from '@angular/core';

// ionic
import { NavController } from '@ionic/angular';

// services
import { AnalyticsService } from 'src/app/services/analytics.service';
import { AppData } from 'src/app/services/app-data.service';

@Component({
    selector: 'app-learning-cards',
    templateUrl: 'learning-cards-page.html',
    styleUrls: ['learning-cards-page.scss']
})
export class LearningCardsPage implements OnInit {
    selectedItem: any;
    icons: string[];
    items: Array<{ title: string, note: string, icon: string }>;

    constructor(public analytics: AnalyticsService,
        public appData: AppData) {
        // If we navigated to this page, we will have an item available as a nav param
        // this.selectedItem = navParams.get('item');

        // Let's populate this page with some filler content for funzies
        this.icons = ['flask', 'wifi', 'beer', 'football', 'basketball', 'paper-plane',
            'american-football', 'boat', 'bluetooth', 'build'];

        this.items = [];
        for (let i = 1; i < 11; i++) {
            this.items.push({
                title: 'Item ' + i,
                note: 'This is item #' + i,
                icon: this.icons[Math.floor(Math.random() * this.icons.length)]
            });
        }
    }

    ngOnInit() {
        this.analytics.trackPageView('learning-cards');
    }

    itemTapped(event, item) {
        // That's right, we're pushing to ourselves!
        // this.navCtrl.push(LearningCardsPage, {
        //     item: item
        // });
    }
}
