import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { ModalController, Platform, PopoverController } from '@ionic/angular';
import {
    CreateClassPaidFeaturePopupComponent
} from '../components/create-class-paid-feature-popup/create-class-paid-feature-popup.component';
import { CreateClassCompleteOnboardingModalComponent } from '../components/create-class-complete-onboarding-modal/create-class-complete-onboarding-modal.component';
import { AppData } from '../services/app-data.service';
import LicenseType from '../interfaces/ILicenseType';
import { Constants } from '../app.constants';
import { ILicense } from '../interfaces/ILicense';


@Injectable()
export class CreateClassGuard implements CanActivate {

    constructor(private router:Router,
        private appData: AppData,
        private modalController: ModalController,
        private platform: Platform,
        private constants: Constants
    ) {

    }

    canActivate(route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): boolean|UrlTree {
        const educatorPreferences = this.appData.authenticatedUser?.educator_preferences;
        const license: ILicense = JSON.parse(this.appData.getPreferenceString(this.constants.pref.LICENSE))  as ILicense;
        const isOnboardingCompleteStorage = this.appData.getPreferenceString(this.constants.pref.ONBOARDING_EDUCATOR_DONE);
        const isOnboardingCompleteFromServer = educatorPreferences.onboarding_complete?.completed;
        console.log('from server ', isOnboardingCompleteFromServer);
        if (!isOnboardingCompleteStorage && !isOnboardingCompleteFromServer) {
            this.showCompleteOnboardingPopup();
            const onboardingRedirectUrl = this.appData.getPreferenceString(this.constants.pref.ONBOARDING_REDIRECT_URL, 'onboarding');
            return this.router.createUrlTree([`/${onboardingRedirectUrl}`]);
        }
        if (license && license.type === LicenseType.FREE) {
            this.showPopup();
            return false;
        }
        return true;
    }

    private async showPopup() {
        this.modalController.create({
            component: CreateClassPaidFeaturePopupComponent,
            cssClass: 'modal-premium-feature-gif',
            componentProps: {
                isModalOpen: true, // Pass the necessary input to the component
            },
        }).then(modal => {
            modal.present();
        });
    }

    private async showCompleteOnboardingPopup() {
        this.modalController.create({
            component: CreateClassCompleteOnboardingModalComponent,
            cssClass: 'modal-premium-feature-gif',
            componentProps: {
                isModalOpen: true, // Pass the necessary input to the component
            },
        }).then(modal => {
            modal.present();
        });
    }

}
