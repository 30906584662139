<ion-header>
    <ion-toolbar [color]="appData.isEducator() ? 'blue' : appData.isStudent() ? 'green' : 'secondary'">
        <ion-buttons slot="start">
            <ion-menu-toggle>
                <ion-button>
                    <ion-icon slot="icon-only" name="menu"></ion-icon>
                </ion-button>
            </ion-menu-toggle>
        </ion-buttons>
        <ion-title>Learning Cards</ion-title>
    </ion-toolbar>
</ion-header>

<ion-content>
    <ion-list>
        <ion-item>
            <ion-button *ngFor="let item of items" (click)="itemTapped($event, item)">
                <ion-icon [name]="item.icon" slot="start"></ion-icon>
                {{item.title}}
                <div class="item-note" slot="end">
                    {{item.note}}
                </div>
            </ion-button>
        </ion-item>

    </ion-list>
    <div *ngIf="selectedItem" padding>
        You navigated here from <b>{{selectedItem.title}}</b>
    </div>
</ion-content>
