// angular
import { Component, ViewChild, OnInit, HostListener, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeStyle, Title } from '@angular/platform-browser';

// ionic
    import { LoadingController, Platform, NavController, IonSlides } from '@ionic/angular';

// services
import { Utils } from '../../utils/utils';
import { AppData } from 'src/app/services/app-data.service';
import { AnalyticsCategory, AnalyticsService } from 'src/app/services/analytics.service';
import { UiUtils } from 'src/app/services/ui-utils.service';
import { WordCatalogApi } from 'src/app/services/api/wordcatalog.service';
import { WordCatalogHelperService } from 'src/app/services/word-catalog-helper.service';

// models
import { SavedWord } from '../../models/saved-word.model';
import { IWordCatalog } from 'src/app/interfaces/IWordCatalog';

// libraries
import { TranslateService } from '@ngx-translate/core';
import shuffle from 'shuffle-array';
import { ActivatedRoute, NavigationExtras } from '@angular/router';
import { IGroup } from 'src/app/interfaces/IGroup';
import { ActivityService } from 'src/app/services/activity.service';
import { IActivityEvent, ActivityCategory, ActivityAction } from 'src/app/interfaces/IActivity';
import { Constants } from 'src/app/app.constants';
import { NGXLogger } from 'ngx-logger';
import { ILanguages } from '../../interfaces/ILanguages';
import { WordCatalogCardDetailsResponse } from '../../models/dtos';
import Swiper from 'swiper';

@Component({
    selector: 'app-flash-cards',
    templateUrl: 'flash-cards-page.html',
    styleUrls: ['flash-cards-page.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FlashCardsPage implements OnInit {
    index = 0;
    wordCatalog: SavedWord[] | IWordCatalog[] = null;
    wordCardDetails: Map<string, WordCatalogCardDetailsResponse> = new Map();
    beforeWord: string[] = [];
    afterWord: string[] = [];
    wordFromSentence: string[] = [];
    class_id: string;
    class: IGroup;
    safeBackgroundUrls: Map<string, SafeStyle> = new Map();
    swiper: Swiper;

    // @ViewChild(IonSlides, { static: false }) slides: IonSlides;

    slideOptions = {
        pagination: {
            el: '.swiper-pagination',
            type: 'progressbar',
        }
    };

    constructor(private _route: ActivatedRoute,
        private navCtrl: NavController,
        public appData: AppData,
        public translate: TranslateService,
        public analytics: AnalyticsService,
        public loadingCtrl: LoadingController,
        public uiUtils: UiUtils,
        private sanitizer: DomSanitizer,
        public plt: Platform,
        public wordCatalogApi: WordCatalogApi,
        public constants: Constants,
        public activityService: ActivityService,
        private titleService: Title,
        private logger: NGXLogger,
        private wordCatalogHelperService: WordCatalogHelperService
    ) {
        // Doing nothing.
    }

    ngOnInit() {
        this.titleService.setTitle('Flash Cards | uugot.it');
        this.analytics.trackPageView('flash-cards');
    }

    ionViewWillEnter() {
        this.analytics.trackPageView('flash-cards');
        this.class_id = this._route.snapshot.params.class_id;
        if (this.class_id) {
            // this.appData.activePage = `flash-cards/${this.class_id}`;
            // this.appData.activeGroupId = this.class_id;
            this.appData.activePage = 'flash-cards-groups';
        } else {
            this.appData.activePage = 'flash-cards';
        }
        // this.class = this._route.snapshot.data.data.group;

        // this.wordCatalog = shuffle(this._route.snapshot.data.data.wordCatalog);
        // this.getSentences();

        this._route.snapshot.data.resData.subscribe(data => {
            // this.logger.debug('FlashCardsPage resData: ', data);
            if (data) {
                const initializeWordcatalog = () => {
                    this.wordCatalog = shuffle(data.wordCatalog);
                    this.class = data.group;
                    this.getSentences();
                    this.wordCatalog.forEach(savedWord => {
                        // We need do sanitize the URL due to Angular security restrictions
                        if (!this.safeBackgroundUrls[savedWord.imageURL]) {
                            this.safeBackgroundUrls[savedWord.imageURL] = this.sanitizer.bypassSecurityTrustStyle(`url(${savedWord.imageURL})`);
                        }
                    });
                    this.initializeSlides();
                };

                // Load first word details
                if (data.wordCatalog?.length > 0) {
                    const word = data.wordCatalog[0];
                    this.wordCatalogApi.getCardDetails(word._id).toPromise().then(cardInfo => {
                        this.wordCardDetails.set(word._id, cardInfo);
                    }).catch(err => {
                        this.logger.error('Error loading card details 1:', err);
                    }).finally(() => {
                        initializeWordcatalog();
                    });
                } else {
                    initializeWordcatalog();
                }
                // Load second word details
                if (data.wordCatalog?.length > 1) {
                    const word = data.wordCatalog[1];
                    this.wordCatalogApi.getCardDetails(word._id).toPromise().then(cardInfo => {
                        this.wordCardDetails.set(word._id, cardInfo);
                    }).catch(err => {
                        this.logger.error('Error loading card details 2:', err);
                    });
                }
            } else {
                this.logger.debug('FlashCardsPage got no data');
            }
        }, err => {
            this.logger.error('Error getting flash cards data', err);
            this.uiUtils.showErrorAlert(err.message || err.msg);
        });
    }

    /**
     * Initializes slides
     */
    initializeSlides() {
        this.swiper = new Swiper('#swiper', {
            pagination: {
                el: '.swiper-pagination',
                type: 'progressbar',
            },
        });

        this.appData.activeGroupId = this.class_id;
        // this.appData.activePage = this.class_id ? `word-catalog/${this.class_id}` : 'word-catalog';
        this.appData.activePage = this.class_id ? `flash-cards-groups` : 'flash-cards';
        this.wordCatalog.forEach(savedWord => {
            // We need do sanitize the URL due to Angular security restrictions
            if (!this.safeBackgroundUrls[savedWord.imageURL]) {
                this.safeBackgroundUrls[savedWord.imageURL] = this.sanitizer.bypassSecurityTrustStyle(`url(${savedWord.imageURL})`);
            }
        });
    }

    /**
     * Called when a card is clicked
     *
     * @param word The word that was clicked
     */
    cardClicked(word: SavedWord) {
        // We add some timeout, so that it doesn't appear instantly
        setTimeout(() => {
            this.logger.debug('Card clicked: ' + word.oriWord + ' type of numFlipped: ' + typeof (word.numFlipped));
            if (word.numFlipped === undefined) {
                word.numFlipped = 0;
                this.logger.debug('Setting numFlipped to 0');
            }

            word.numFlipped++;
            this.analytics.trackAnalyticsEvent(AnalyticsCategory.FlashCards, 'flipped', word.oriWord, word.numFlipped);

            if (word.numFlipped === 2) {
                // If flipped 2 times (=sentence is revealed), then we save an event "word_learned"
                if (this.appData.isStudent()) {
                    const event: IActivityEvent = {
                        installationID: this.appData.getInstallationId(),
                        category: ActivityCategory.word,
                        action: ActivityAction.word_learned,
                        label: word.oriWord,
                        generated_at: new Date()
                    };
                    if (this.class_id && this.class_id !== this.constants.GeneralClassId) {
                        event.group_id = this.class_id;
                    }

                    this.activityService.saveEventToStorage(event);
                }
            }
        }, 300);
    }

    /**
     * Separates each sentence into before original word and after
     */
    getSentences() {
        this.wordCatalog.forEach((word: SavedWord | IWordCatalog, index: number) => {
            if (word.sentence) {
                let fromWord = word.fromWord || word.oriWord;
                if ('fromToken' in word && word.fromToken) {
                    fromWord = word.fromToken.text;
                }
                const chunks = fromWord.split(' ');
                const firstPos = word.sentence.indexOf(chunks[0]);
                const lastPos = word.sentence.indexOf(chunks[chunks.length - 1]);
                this.beforeWord[index] = word.sentence.slice(0, firstPos);
                this.wordFromSentence[index] = word.sentence.slice(firstPos, lastPos + chunks[chunks.length - 1].length);
                this.afterWord[index] = word.sentence.slice(lastPos + chunks[chunks.length - 1].length);
            }
        });
    }

    /**
     * Wraps original word in a <span>
     *
     * @param word The word to be wrapped
     * @returns Returns the sentence with the originalWord wrapped in a <span>
     */
    getSentence(word: SavedWord | IWordCatalog): string {
        const details = this.wordCardDetails.get(word._id);
        return this.wordCatalogHelperService.getSentence(word, details);
    }

    getFromWord(word: SavedWord | IWordCatalog): string {
        return this.wordCatalogHelperService.getFromWord(
            word,
            this.wordCardDetails
        );
    }

    /**
     * Called when the slide is changed
     */
    async slideChanged() {
        this.analytics.trackAnalyticsEvent(AnalyticsCategory.WordCatalog, 'slide_changed');
        this.index = this.swiper?.activeIndex;
    }

    /**
     * Get visibility of the sentence containing the word
     *
     * @param word The word
     * @returns True if the word has been clicked at least 2 times, false otherwise
     */
    getVisibility(word: SavedWord): string {
        return word.numFlipped >= 2 ? 'visible' : 'hidden';
    }

    /**
     * Called when the user clicks the shuffle cards button
     */
    async shuffleButtonClicked() {
        for (const word of this.wordCatalog) {
            // Reset flipped counter
            word.numFlipped = 0;
        }

        const shuffled = shuffle(this.wordCatalog);
        this.wordCatalog = null;
        setTimeout(() => {
            this.wordCatalog = shuffled;
            this.getSentences();
            this.swiper.slideTo(0, 0);
        }, 700);

        const loader = await this.loadingCtrl.create({
            message: this.translate.instant('cards_are_being_shuffled'),
            duration: 700
        });
        await loader.present();
    }

    /**
     * Opens video player page
     *
     * @param videoId The video ID
     * @param source The source of the video
     * @param timeInVideo The time to start the video
     */
    openVideoPlayerPage(word: SavedWord|IWordCatalog): void {

        const videoId = word.video_id;
        const source = word.websource_id;
        // Backwards compatibility (no sentence_start):
        const timeInVideo = word.sentence_start !== null ? word.sentence_start : word.start;

        // videoId: string, source: string, timeInVideo: number
        this.logger.debug('Opening video player page with id %s and time timeInVideo %s', videoId, timeInVideo);
        this.analytics.trackAnalyticsEvent(AnalyticsCategory.WordCatalog, 'play_clip', videoId);

        // this.appData.timeInVideo = timeInVideo; // This is not needed any more because we have looping and clipping
        let videoPath = `video/${source}/${videoId}`;
        if (this.class_id) {
            videoPath += `/${this.class_id}`;
        }

        const clippingStart = Math.max(timeInVideo - 0.2, 0);
        const clippingEnd = word.sentence_end !== null ? word.sentence_end + 0.5 : timeInVideo + 6;

        const navigationExtras: NavigationExtras = {
            state: {
                maxLoops: 3, // 1 = disable looping
                clippingStart,
                clippingEnd,
                autoStart: true,
                backRoute: this.class_id ? `flash-cards/${this.class_id}` : 'flash-cards/general',
                toLanguage: word.to
            }
        };

        this.navCtrl.navigateForward(videoPath, navigationExtras);
    }

    /**
     * Slides to the next slide
     */
    slideNext() {
        this.swiper?.slideNext();
        this.slideChanged();
        // this.slides.slideNext(500);
    }

    /**
     * Slides to the previous slide
     */
    slidePrev() {
        this.swiper?.slidePrev();
        this.slideChanged();
        // this.slides.slidePrev(500);
    }

    splitWordIntoLinesJoin(word: SavedWord | IWordCatalog): string {
        const overrideFromAndToWord = this.wordCatalogHelperService.getOverrideFromAndToWords(word, this.wordCardDetails);
        const toWord = overrideFromAndToWord ? overrideFromAndToWord.toWord : word.toWord;
        const toLanguage: ILanguages = word.to;
        return Utils.splitWordIntoLinesJoin(toWord, toLanguage, '<br>');
    }

    /**
     * Navigates back to the class which the word catalog belongs to
     */
    navigateBack() {
        // this.appData.activePage = '';

        // this.navCtrl.navigateBack(`student-class/details/${this.class_id}`);

        this.navCtrl.navigateBack('flash-cards-groups');
    }

    @HostListener('window:keyup', ['$event'])
    keyEvent(event: KeyboardEvent) {
        console.log(event);

        if (event.key === 'ArrowLeft') {
            this.slidePrev();
        }

        if (event.key === 'ArrowRight') {
            this.slideNext();
        }
    }
}
