import { OnInit, Component, OnDestroy } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { NavParams, PopoverController } from '@ionic/angular';
import { UiUtils } from 'src/app/services/ui-utils.service';
import { IVideo } from 'src/app/interfaces/IVideo';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, interval } from 'rxjs';
import { AppData } from 'src/app/services/app-data.service';

/**
 * A page (popover) showing the "embed code" for a video.
 * @deprecated we use VideoPlayNextOverlay instead.
 */
@Component({
    template: `
        <div class="ion-padding-horizontal ion-padding-bottom">
            <!--  style="padding-bottom: 10px;" -->
            <!--<ion-button
                (click)="dismiss()"
                fill="clear"
                color="dark"
                style="position: absolute; right: 3px; top: 3px;"
            >
                <ion-icon slot="icon-only" name="close"></ion-icon>
            </ion-button>-->

            <div *ngIf="showReviewWordsPrompt">
                <h3>{{ 'review_words_header' | translate }}</h3>
                <p>{{ 'review_words_message' | translate }}</p>
                <div style="text-align: right;">
                    <ion-button (click)="dismiss('goToNext')" fill="solid">
                        {{ 'yes_review' | translate }}
                    </ion-button>
                </div>
            </div>

            <p>
                Nächstes Video in <strong>{{ countdownMaxSec - countdownCurrentSec }}</strong>
            </p>

            <ion-grid>
                <ion-row>
                    <ion-col size="6"><img src="{{ video.imageURL }}" /></ion-col>
                    <ion-col size="6">
                        <div style="font-size: 0.9em; font-weight: 600;">{{ video.title }}</div>
                    </ion-col>
                </ion-row>
            </ion-grid>

            <div style="text-align: right;">
                <ion-button (click)="dismiss('cancel')" fill="clear">
                    {{ 'btn_cancel' | translate }}
                </ion-button>
                <ion-button (click)="dismiss('goToNext')" fill="solid">
                    Jetzt wiedergeben
                </ion-button>
            </div>
        </div>
    `,
    // styles: [
    //     `
    //     .embed-code-block {
    //         padding: 10px;
    //         background-color: #eee;
    //         font-family: monospace;
    //         font-size: 1.1em;
    //         -webkit-user-select: all; /* Safari */
    //         -ms-user-select: all; /* IE 10+ and Edge */
    //         user-select: all; /* Standard syntax */
    //     }
    //     `
    // ],
})
export class VideoPlayerNextVideoPopoverPage implements OnInit, OnDestroy {
    video: IVideo = null;
    showReviewWordsPrompt = false;
    countdownCurrentSec = 0;
    countdownMaxSec = -1;
    private countdownSubscription: Subscription;

    constructor(
        private navParams: NavParams,
        private analytics: AnalyticsService,
        private logger: NGXLogger,
        private popoverCtrl: PopoverController,
        public uiUtils: UiUtils,
        public translate: TranslateService,
        public appData: AppData
    ) {
        // do nothing.
    }

    ngOnInit() {
        this.video = this.navParams.get('video');
        this.showReviewWordsPrompt = this.navParams.get('showReviewWordsPrompt');
        this.countdownMaxSec = this.showReviewWordsPrompt ? 10 : 5;

        this.countdownSubscription = interval(1000).subscribe((value) => {
            this.logger.debug('countdown', value);
            this.countdownCurrentSec = value;
            if (this.countdownMaxSec - this.countdownCurrentSec <= 0) {
                this.dismiss('goToNext');
            }
        });
    }

    ngOnDestroy() {
        if (this.countdownSubscription) {
            this.countdownSubscription.unsubscribe();
        }
    }

    dismiss(resultAction: string) {
        this.popoverCtrl.dismiss({
            resultAction,
            video: this.video,
        });
    }
}
