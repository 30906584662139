import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import { SearchbarCustomEvent } from '@ionic/angular';

@Component({
    selector: 'app-searchable-select',
    templateUrl: './searchable-select.component.html',
    styleUrls: ['./searchable-select.component.scss']
})
export class SearchableSelectComponent implements OnChanges, OnInit {
    @Input() title: string;
    @Input() data: any[];
    @Input() existingData: any[];
    @Input() itemTextField: string;
    @Input() multiple: boolean;
    @Output() selectedChange: EventEmitter<any> = new EventEmitter();
    isOpen = false;
    selected = [];
    filtered = [];

    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor() {
    }

    ngOnInit(): void {
        if (this.existingData.length > 0) {
            this.selected = this.existingData;
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.filtered = this.data;
    }

    open() {
        this.isOpen = true;
    }

    close() {
        this.isOpen = false;
    }

    confirm() {
        const selected = this.data.filter((item) => item.selected);
        this.selected = selected;
        this.selectedChange.emit(selected);
        this.isOpen = false;
    }

    itemSelected() {
        if (!this.multiple) {
            if (this.selected.length) {
                this.selected[0].selected = false;
            }
            this.selected = this.data.filter((item) => item.selected);

            this.selectedChange.emit(this.selected);
            this.isOpen = false;
        }
    }
    leaf = (obj) => this.itemTextField.split('.').reduce((value, el) => value[el], obj);

    filter(event: SearchbarCustomEvent) {
        const filter = event.detail.value.toLowerCase();
        this.filtered = this.data.filter(item => this.leaf(item).toLowerCase().indexOf(filter) >= 0);
    }

}
